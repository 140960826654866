import React, { useState, useEffect } from "react";
import {
  Card,
  Container,
  Typography,
  TableContainer,
  Button,
  Box,
  IconButton,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ListToolbar from "./ListToolbar";
import DeleteTradeTable from "./DeleteTradeTable";
import EditIcon from "@mui/icons-material/Edit";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../../Style/TradeBlotter.css";
import {
  TABLE_HEAD_TRADE as TABLE_HEAD,
  historicalData,
  tradesData,
} from "../../Methods";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import moment from "moment";
import Popup from "../../Popup";
import TradesDelete from "../../Popup/TradesDelete";
import EditTradeDetails from "../../../Pages/EditTradeDetails";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import BulkEdit from "../../Popup/BulkEdit";
import BulkDelete from "../../Popup/BulkDelete";
import DetailPanelContent from "./DetailPanelContent";
import { TradesTable } from "../../Methods/columnHeader";
import { updateLoading } from "../../../Redux/Reducers/todoReducers";

const RebalancerTradeTable = () => {
  const dispatch = useDispatch();
  const currentDate = new Date();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const year = currentDate.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;
  const startOfDay = new Date(formattedDate).getTime();
  const endOfDay = startOfDay + 86400000 - 60;
  const [datestate, setDatestate] = useState({
    start: startOfDay,
    end: endOfDay,
  });

  const getTableConfig = localStorage.getItem("tableconfig");
  const [initial, setInitial] = useState(true);
  const [prioritySwitch, setSwitch] = useState(2);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [data, setData] = useState([]);
  const [updatedArray, setUpdatedArray] = useState([]);
  const [historicalTrades, setHistoricalTrades] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [showIcon, setShowIcon] = useState(false);
  const [bulkEdit, setBulkEdit] = useState(false);
  const [bulkdelete, setBulkdelete] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [TradeForEdit, setTradeForEdit] = useState(null);
  const [recordForDelete, setrecordForDelete] = useState(null);
  const [openDeletePopup, setDeleteOpenPopup] = useState(false);
  const [openCrossPopup, seOpenCrossPopup] = useState(false);

  const [tableConfig, setTableConfig] = useState(
    getTableConfig || TABLE_HEAD || []
  );
  const user = useSelector((state) => state?.users?.user?.username);
  const {
    trades,
    funds,
    custodians,
    routeList,
    strategies,
    historical,
    managers,
    rebalancedTrades,
  } = useSelector((state) => state?.todos);
  const brokers = useSelector((state) => state?.todos?.data);
  const getDetailPanelHeight = React.useCallback(() => 400, []);

  useEffect(() => {
    if (historical) {
      const SimpleArray = historicalData(
        historical,
        funds,
        brokers,
        strategies,
        custodians
      );
      let filteredSimpleArray = SimpleArray;
      if (!managers || managers.length === 0) {
        filteredSimpleArray = SimpleArray?.filter(
          (item) => item.strategy !== undefined && item.strategy !== ""
        );
      }
      const filteredData = filteredSimpleArray?.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      setHistoricalTrades(filteredData);
    }
  }, [historical, trades, rebalancedTrades]);

  useEffect(() => {
    if (trades) {
      const SimpleArray = tradesData(
        trades,
        funds,
        brokers,
        routeList,
        strategies,
        custodians,
        prioritySwitch,
        page,
        rowsPerPage,
        startOfDay,
        endOfDay
      );

      let filteredSimpleArray = SimpleArray;
      if (!managers || managers.length === 0) {
        filteredSimpleArray = SimpleArray?.filter(
          (item) => item.strategy !== undefined && item.strategy !== ""
        );
      }

      const filteredData = filteredSimpleArray?.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      setUpdatedArray(filteredData);
    }
  }, [trades, rebalancedTrades]);
  useEffect(() => {
    if (trades && updatedArray) {
      const filteredData = updatedArray
        .filter(
          (row) =>
            row?.TradeTime > datestate.start && row?.TradeTime < datestate.end
        )
        ?.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
      setData(filteredData);
    }
  }, [trades, updatedArray, datestate]);

  const handleDateBackward = (st, en) => {
    let start = new Date(st - 86400000).getTime();
    let end = new Date(en - 86400000).getTime();
    if (startOfDay > start) {
      setData(historicalTrades);
      setInitial(false);
    }
    return { start, end };
  };

  const handleDateForward = (st, en) => {
    let start = new Date(st + 86400000).getTime();
    let end = new Date(en + 86400000).getTime();
    if (start < startOfDay) {
      setData(historicalTrades);
      setInitial(false);
    } else {
      setData(updatedArray);
      setInitial(true);
    }
    return { start, end };
  };
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setPage(0);
    setRowsPerPage(pageSize);
  };

  const theme = useTheme();
  const openTradeInPopup = (item) => {
    setTradeForEdit(item);
    setOpenPopup(true);
  };
  const openInDeletePopup = (item) => {
    setDeleteOpenPopup(true);
    setrecordForDelete(item);
  };
  const openInmultiplePopup = (item) => {
    seOpenCrossPopup(true);
    setTradeForEdit(item);
  };
  const handleSelectionModelChange = (newSelectionModel) => {
    if (newSelectionModel.length > 1) {
      setShowIcon(true);
    } else {
      setShowIcon(false);
    }

    const selectedRows = newSelectionModel.map((rowId) => {
      const selectedItem = updatedArray.find((item) => item.id === rowId);

      return selectedItem || null;
    });

    setSelectionModel(selectedRows);
  };

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    []
  );
  const handleReq = () => {
    const data = {
      action: "create_rebalancer_orders",
      data: {
        rebalancerIds: [
          "d00a4e2bebae177c29e9700e3286c4cb",
          "ce61c8d36138e3c839690acf37e45f4d",
        ],
        execute: false,
      },
    };

    window.clientSocks.send(JSON.stringify(data));
    dispatch(updateLoading(true));
  };
  return (
    <>
      <Container maxWidth="70%" height={100} sx={{ marginTop: 1 }}>
        <Card>
          <ListToolbar
            data={data}
            setTableHead={setTableConfig}
            headLabel={tableConfig}
          />

          <Box
            sx={{
              marginLeft: "600px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "250px",
              background: theme.palette.grey[700],
            }}
          >
            <Button
              onClick={() => {
                let today = handleDateBackward(datestate.start, datestate.end);
                setDatestate(today);
              }}
              startIcon={
                <ArrowBackIcon
                  sx={{ width: "30px", color: theme.palette.grey[100] }}
                />
              }
            ></Button>
            <hr style={{ color: theme.palette.grey[100] }} />
            <Box
              sx={{
                width: "100px",
                display: "flex",
                justifyContent: "center",
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  color: theme.palette.grey[100],
                }}
              >
                {moment(datestate.start).format("MM/DD/YY")}
              </Typography>
            </Box>
            <hr style={{ color: theme.palette.grey[100] }} />
            <Button
              onClick={() => {
                if (datestate.start < startOfDay) {
                  let today = handleDateForward(datestate.start, datestate.end);
                  setDatestate(today);
                }
              }}
              startIcon={
                <ArrowForwardIcon
                  sx={{ width: "30px", color: theme.palette.grey[100] }}
                />
              }
            ></Button>
          </Box>
          <Button onClick={handleReq}>request data</Button>
          <hr />
          <TableContainer style={{ height: "47vh", minHeight: "47vh" }}>
            <DataGridPro
              columns={TradesTable(
                theme,
                initial,
                openInDeletePopup,
                openTradeInPopup,
                openInmultiplePopup
              )}
              rows={data}
              rowThreshold={0}
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
              height={300}
              rowHeight={15}
              checkboxSelection
              pageSize={rowsPerPage}
              rowsPerPageOptions={[17, 50, 100]}
              page={page}
              onPageChange={handleChangePage}
              onPageSizeChange={handleChangeRowsPerPage}
              onSelectionModelChange={handleSelectionModelChange}
              density="standard"
              components={{
                Toolbar: () => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <GridToolbar
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        "& .MuiButton-root": {
                          px: "4px",
                          py: 0,
                          color: theme.palette.grey[600],
                        },
                        "& .MuiSvgIcon-root": {
                          height: "15px",
                          width: "15px",
                          color: theme.palette.grey[600],
                        },
                      }}
                    />

                    {showIcon && (
                      <>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setBulkEdit(true);
                          }}
                        >
                          <EditIcon
                            sx={{
                              color: "#37404c",
                              width: "15px !important",
                              height: "15px !important",
                              marginRight: "-2px !important",
                            }}
                          />{" "}
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              display: "block",
                              marginLeft: "2px",
                              color: "#37404c",
                              marginLeft: "7px",
                            }}
                          >
                            BULK EDIT
                          </span>
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setBulkdelete(true);
                          }}
                        >
                          <DeleteIcon
                            sx={{
                              color: "#37404c",
                              width: "15px !important",
                              height: "15px !important",
                              marginRight: "-2px !important",
                            }}
                          />
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              display: "block",
                              marginLeft: "2px",
                              color: "#37404c",
                              marginLeft: "7px",
                            }}
                          >
                            BULK DELETE
                          </span>
                        </IconButton>{" "}
                        <IconButton
                          size="small"
                          onClick={() => {
                            // setBulkEdit(true);
                          }}
                        >
                          <AutoFixHighIcon
                            sx={{
                              color: "#37404c",
                              width: "15px !important",
                              height: "15px !important",
                              marginRight: "-2px !important",
                            }}
                          />{" "}
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              display: "block",
                              marginLeft: "2px",
                              color: "#37404c",
                              marginLeft: "7px",
                            }}
                          >
                            BULK ROUTE
                          </span>
                        </IconButton>
                      </>
                    )}
                  </div>
                ),
              }}
              sx={{
                "& .MuiDataGrid-sortIcon": {
                  display: "none",
                },
                "& .MuiDataGrid-row": {
                  padding: "0px",
                  fontSize: "12px",
                },
                "& .MuiDataGrid-columnHeaders": {
                  color: theme.palette.grey[100],
                  background: theme.palette.grey[600],
                  fontSize: "11px",
                  padding: "0px",
                },
                "& .MuiDataGrid-iconSeparator": {
                  display: "none",
                },
              }}
            />
          </TableContainer>
        </Card>
      </Container>
      <TradesDelete
        title="Delete Trade"
        openDeletePopup={openDeletePopup}
        setDeleteOpenPopup={setDeleteOpenPopup}
        recordForDelete={recordForDelete}
        user={user}
        date={datestate}
      />

      <BulkEdit
        bulkEdit={bulkEdit}
        setBulkEdit={setBulkEdit}
        selectionModel={selectionModel}
        title="Bulk Update"
      />
      <BulkDelete
        bulkdelete={bulkdelete}
        setBulkdelete={setBulkdelete}
        selectionModel={selectionModel}
        user={user}
        title="Bulk Delete"
      />
      <Popup
        title="Edit Trade Entry"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <EditTradeDetails
          setOpenPopup={setOpenPopup}
          TradeForEdit={TradeForEdit}
          setTradeForEdit={setTradeForEdit}
        />
      </Popup>
      
      <br />
      <DeleteTradeTable mydate={datestate} prioritySwitch={prioritySwitch} />
    </>
  );
};
export default RebalancerTradeTable;
