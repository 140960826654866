import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  IconButton,
  useTheme,
  TablePagination,
  Box,
} from "@mui/material";
import ListToolbar from "./ListToolbar";
import ListHead from "../../ListHead";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getComparator,
  TABLE_HEAD_USER as TABLE_HEAD,
  Tablecell,
  applySortFilter,
} from "../../Methods";
import Popup from "../../Popup";
import DeletePopUp from "../../Popup/DeletePopUp";
import ClosedCaptionDisabledIcon from "@mui/icons-material/ClosedCaptionDisabled";
import EditUser from "../EditUser";
import Blacklist from "../Blacklist";

const UserTable = () => {
  const theme = useTheme();
  const { managers } = useSelector((state) => state?.todos);
  const [data, setData] = useState([]);
  const [managerId, setManagerId] = useState();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("email");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [recordForDelete, setrecordForDelete] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeletePopup, setDeleteOpenPopup] = useState(false);
  const [openBlacklistPopup, setBlacklistOpenPopup] = useState(false);
  const [BlacklistData, setBlacklistData] = useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  useEffect(() => {
    if (managers && managers?.length > 0) {
      const filteredUsers = applySortFilter(
        managers,
        getComparator(order, orderBy),
        filterName
      );
      setData(filteredUsers);
    }
  }, [order, orderBy, filterName, managers]);

  const openInPopupBlacklist = (item) => {
    setManagerId(item?.managerId);
    setBlacklistData(item?.blacklist);
    setBlacklistOpenPopup(true);
  };
  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const openInDeletePopup = (item) => {
    setrecordForDelete(item);
    setDeleteOpenPopup(true);
  };

  const isNotFound = !data.length && !!filterName;

  return (
    <>
      <Container maxWidth="70%" sx={{ marginTop: 1, marginBottom: 3 }}>
        <Card>
          <ListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setOrderBy={setOrderBy}
            setOrder={setOrder}
            setData={setData}
            data={data}
          />

          <TableContainer style={{ height: "62vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="a dense table"
              sx={{
                height: "max-content",
              }}
            >
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {data &&
                  data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          sx={{
                            "&:hover": {
                              background: theme.palette.grey[200],
                              cursor: "pointer",
                              ".deleteIcon": {
                                visibility: "visible",
                              },
                            },
                          }}
                        >
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {index + 1 + page * rowsPerPage}
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {row?.owner}
                          </Tablecell>

                          <Tablecell
                            align="center"
                            sx={{
                              paddingLeft: "5px",
                              width: "150px",
                            }}
                          >
                            <Box
                              component="span"
                              sx={{
                                fontSize: "9px",
                                fontWeight: "500",
                              }}
                            >
                              <IconButton
                                sx={{ height: "20px", Minwidth: "10px" }}
                                onClick={() => {
                                  openInPopupBlacklist(row);
                                }}
                              >
                                <ClosedCaptionDisabledIcon
                                  sx={{
                                    color: "#808080",
                                    width: "20px",
                                    height: "15px",
                                  }}
                                />
                              </IconButton>
                            </Box>
                          </Tablecell>
                          <Tablecell
                            align="center"
                            sx={{
                              paddingLeft: "5px",
                              width: "150px",
                            }}
                          >
                            <Box
                              component="span"
                              sx={{
                                fontSize: "9px",
                                fontWeight: "500",
                                visibility: "hidden",
                              }}
                              className="deleteIcon"
                            >
                              <IconButton
                                sx={{ height: "20px", Minwidth: "10px" }}
                                onClick={() => {
                                  openInPopup(row);
                                }}
                              >
                                <EditIcon
                                  sx={{
                                    color: "#808080",
                                    width: "20px",
                                    height: "15px",
                                  }}
                                />
                              </IconButton>
                              <IconButton
                                sx={{ height: "20px", Minwidth: "10px" }}
                                onClick={() => {
                                  openInDeletePopup(row);
                                }}
                              >
                                <DeleteIcon
                                  sx={{
                                    color: "#808080",
                                    width: "20px",
                                    height: "15px",
                                  }}
                                />
                              </IconButton>
                            </Box>
                          </Tablecell>
                        </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[100, 500, 1000]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <DeletePopUp
        title="DELETE USER"
        openDeletePopup={openDeletePopup}
        setDeleteOpenPopup={setDeleteOpenPopup}
        recordForDelete={recordForDelete}
        deleteObj="delete_user"
        item="user"
      />

      <Popup
        title="EDIT USER"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <EditUser recordForEdit={recordForEdit} setOpenPopup={setOpenPopup} />
      </Popup>
      <Blacklist
        title="Specify Blacklist Securities"
        openBlacklistPopup={openBlacklistPopup}
        setBlacklistOpenPopup={setBlacklistOpenPopup}
        BlacklistData={BlacklistData}
        managerId={managerId}
      />
    </>
  );
};
export default UserTable;
