import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  Typography,
} from "@mui/material";
import VantageListHead from "../../../../../../ListHead/SignalList";
import {
  Tablecell,
  applySortFilter,
  getComparator,
  findStrategy,
} from "../../../../../../Methods";
import { useSelector } from "react-redux";
import { values } from "lodash";

const formatNumber = (value) => {
  const num = parseInt(value, 10);
  if (isNaN(num)) return value;
  const absNum = Math.abs(num);
  const formattedNumber = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  }).format(absNum);

  // Append 'k' only if the number is more than three digits
  //   const displayNumber = absNum > 999 ? `${Math.round(absNum / 1000)}k` : formattedNumber;
  const displayNumber = formattedNumber;

  // Display negative numbers in parentheses and add a non-breaking space for alignment
  return num < 0 ? `(${displayNumber})` : `${displayNumber}\u00A0`;
};

const SummaryTable = ({ tableHeader }) => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("column1");
  const [expandedRow, setExpandedRow] = useState(null); // Track expanded row
  const [strategyData, setStrategyData] = useState({});
  const { strategies, rebalancerSummary } = useSelector(
    (state) => state?.todos
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  useEffect(() => {
    const dataToSort = rebalancerSummary?.totals;
    const sortedData = applySortFilter(
      dataToSort,
      getComparator(order, orderBy)
    );

    // Calculate totals
    const totals = sortedData.reduce(
      (acc, row) => {
        acc.tradeCount += parseInt(row.tradeCount, 10);
        acc.shares += parseInt(row.shares, 10);
        acc.dollars += parseInt(row.dollars, 10);
        return acc;
      },
      { tradeCount: 0, shares: 0, dollars: 0 }
    );

    // Add total row
    const totalRow = {
      type: "Total",
      tradeCount: totals.tradeCount.toString(),
      shares: totals.shares.toString(),
      dollars: totals.dollars.toString(),
    };

    setData([...sortedData, totalRow]);
    setStrategyData(rebalancerSummary?.strategyTotals);
  }, [order, orderBy, rebalancerSummary]);

  return (
    <Box
      sx={{
        border: `100px ${theme.palette.lightGrey.tableHeader}`,
        borderRadius: "4px",
        marginTop: "1px",
        width: "100%",
        marginRight: 1,
      }}
    >
      <Box
        sx={{
          maxHeight: "650px",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "5px",
            height: "3px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f0f0f0",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#c0c0c0",
            borderRadius: "10px",
            minHeight: "2px",
          },
        }}
      >
        <Table
          size="small"
          stickyHeader
          aria-label="a dense table"
          sx={{
            borderCollapse: "collapse",
            border: "solid 1px #eee",
            marginTop: "-1px",
          }}
        >
          <thead>
            <Tablecell
              colSpan={tableHeader.length}
              align="center"
              style={{
                backgroundColor: "#313131",
                color: "white",
                fontSize: "12px",
                fontWeight: "bold",
                padding: "8px 0",
              }}
            >
              SUMMARY
            </Tablecell>
          </thead>
          <VantageListHead
            order={order}
            orderBy={orderBy}
            headLabel={tableHeader}
            noStatus={true}
            customizeFont={12}
            rowCount={data.length}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {data?.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow
                  onClick={() => handleRowClick(index)}
                  sx={{
                    "& > .MuiTableCell-root": {
                      paddingTop: "1px",
                      paddingBottom: "0px !important",
                      lineHeight: "1.2",
                      "& .MuiInputBase-input": {
                        padding: "0px 0px !important",
                      },
                    },
                    "&:hover": {
                      background: theme.palette.grey[200],
                      cursor: "pointer",
                    },
                    backgroundColor:
                      row.type === "Total"
                        ? theme.palette.grey[200]
                        : "inherit",
                  }}
                >
                  <Tablecell
                    align="left"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                      fontWeight: "bold",
                    }}
                  >
                    {row.type}
                  </Tablecell>
                  <Tablecell
                    align="right"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                      fontWeight: row.type === "Total" ? "bold" : "normal",
                    }}
                  >
                    {formatNumber(row.tradeCount)}
                  </Tablecell>
                  <Tablecell
                    align="right"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                      fontWeight: row.type === "Total" ? "bold" : "normal",
                    }}
                  >
                    {formatNumber(row.shares)}
                  </Tablecell>
                  <Tablecell
                    align="right"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                      fontWeight: row.type === "Total" ? "bold" : "normal",
                    }}
                  >
                    {formatNumber(row.dollars)}
                  </Tablecell>
                </TableRow>
                {expandedRow === index &&
                  Object.entries(strategyData)
                    .sort(([keyA], [keyB]) =>
                      findStrategy(keyA, strategies).localeCompare(
                        findStrategy(keyB, strategies)
                      )
                    )
                    .map(([key, value]) => (
                      <TableRow
                        key={key}
                        sx={{
                          backgroundColor: "#eeeeee",
                        }}
                      >
                        <Tablecell
                          style={{
                            paddingLeft: "5px", // Add left padding here
                          }}
                        >
                          {findStrategy(key, strategies)}
                        </Tablecell>
                        <Tablecell
                          align="right"
                          style={{
                            fontSize: "12px",
                            paddingLeft: "5px",
                            borderLeft: "none",
                            borderRight: "none",
                          }}
                        >
                          {formatNumber(
                            value.find((i) => i.type === row.type)?.tradeCount
                          )}
                        </Tablecell>
                        <Tablecell
                          align="right"
                          style={{
                            fontSize: "12px",
                            paddingLeft: "5px",
                            borderLeft: "none",
                            borderRight: "none",
                          }}
                        >
                          {formatNumber(
                            value.find((i) => i.type === row.type)?.shares
                          )}
                        </Tablecell>
                        <Tablecell
                          align="right"
                          style={{
                            fontSize: "12px",
                            paddingLeft: "5px",
                            borderLeft: "none",
                            borderRight: "none",
                          }}
                        >
                          {formatNumber(
                            value.find((i) => i.type === row.type)?.dollars
                          )}
                        </Tablecell>
                      </TableRow>
                    ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default SummaryTable;
