import React, { useEffect, useState } from "react";
import { Container, Grid, Button, Box, Alert } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  TABLE_HEAD_FUND_REBALANCE_LOCATES,
  hashAction,
  hashPosition,
} from "../../../../../Methods";
import Locates from "./components/Locates";
import LocatesNotFoundPopup from "../../../../../Popup/LocatesNotFoundPopup";
import { useDispatch, useSelector } from "react-redux";
import { stageTwoOfRebalance } from "../../../../../../Redux/Actions/todoActions";

const FundRebalanceStep2 = ({ setCurrentStep, onPrevious }) => {
  const dispatch = useDispatch();
  const { AllTrades } = useSelector((state) => state.todos);
  const [popupOpen, setPopupOpen] = useState(false);
  const [locatesData, setLocatesData] = useState({});

  const groupByCustodian = (orders) => {
    return orders.reduce((grouped, trade) => {
      const custodian = trade.custodian || "N/A";
      if (!grouped[custodian]) {
        grouped[custodian] = [];
      }
      grouped[custodian].push(trade);
      return grouped;
    }, {});
  };

  useEffect(() => {
    if (AllTrades && Array.isArray(AllTrades?.realOrders)) {
      const TickerTrades = AllTrades?.realOrders;
      if (TickerTrades?.length > 0) {
        const filteredTrades = TickerTrades.filter((trade) => {
          const tradeType =
            hashAction(trade.action) + hashPosition(trade.position);
          return tradeType.toLowerCase() == "sellshort";
        });

        if (filteredTrades?.length > 0) {
          const updatedData = filteredTrades.map((trade) => ({
            ticker: trade.ticker.symbol,
            shareCount: trade.shareCount,
            available: trade?.availableLocates
              ? trade.availableLocates
              : trade.shareCount,
            custodian: trade.custodian,
          }));
          setLocatesData(groupByCustodian(updatedData));
        }
      }
    }
  }, [AllTrades]);

  const groupedOrders = locatesData;

  const handleLocatesDataChange = (updatedData, custodian) => {
    setLocatesData((prevData) => {
      const updatedGroupedOrders = { ...prevData };
      updatedGroupedOrders[custodian] = updatedData;
      return updatedGroupedOrders;
    });
  };

  const updateAvailableLocates = (realOrders, locatesData) => {
    const allLocates = Object.values(locatesData).flat();
    return realOrders.map((trade) => {
      const locatesMatch = allLocates?.find(
        (locate) =>
          locate.ticker.toLowerCase() === trade.ticker.symbol.toLowerCase()
      );
      if (locatesMatch) {
        return {
          ...trade,
          availableLocates: parseFloat(locatesMatch.available),
        };
      }

      return trade;
    });
  };

  const onNext = () => {
    if (locatesData) {
      const updatedTickerTrades = updateAvailableLocates(
        AllTrades.realOrders,
        locatesData
      );

      const allTrades = {
        ...AllTrades,
        realOrders: updatedTickerTrades,
      };
      dispatch(stageTwoOfRebalance(allTrades));
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleFindLocatesClick = () => {
    setPopupOpen(true);
  };
  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <Container disableGutters maxWidth="100%" sx={{ marginTop: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Alert severity="info">
              Locates Available column is editable, enter the numbers or use ⋮
              icon to auto fill from API.
            </Alert>

            {Object.keys(locatesData).length > 0 && (
              <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  onClick={onNext}
                  sx={{ width: "150px", height: "40px" }}
                >
                  Next
                </Button>
              </Box>
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              overflowX: "auto",
              flexWrap: "nowrap",
              gap: 2,
            }}
          >
            {Object.keys(groupedOrders).map((custodian) => (
              <Box key={custodian} sx={{ width: "400px" }}>
                <Locates
                  tickerTrades={groupedOrders[custodian]}
                  setLocatesData={handleLocatesDataChange}
                  tableHeader={TABLE_HEAD_FUND_REBALANCE_LOCATES}
                  custodian={custodian}
                />
              </Box>
            ))}
          </Box>
        </Grid>

        {Object.keys(locatesData).length === 0 && (
          <Grid item xs={12}>
            <p>No data available</p>
          </Grid>
        )}
      </Grid>

      <LocatesNotFoundPopup open={popupOpen} onClose={handleClosePopup} />
    </Container>
  );
};

export default FundRebalanceStep2;
