import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Button,
  FormControl,
  Autocomplete,
  TextField,
  CircularProgress,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import RebalancingInfo from "../../RebalancingInfo";
import SignalsLimitedByLocates from "../../DataEntity/SignalsLimitedByLocates";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { TABLE_HEAD_SIGNAL_LOCATES, findFundById } from "../../../../Methods";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { dummyFunds, dummyLots } from "../../../../../Redux/Reducers/dummy";
import { getSecuritiesWithMarketDataRequest } from "../../../../../utils/helpers";

const FundRebalance = ({ onNext }) => {
  const [selectedFund, setSelectedFund] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSignalsTable, setShowSignalsTable] = useState(false);
  const [ignoreSecurities, setIgnoreSecurities] = useState(false);
  const [ignoredData, setIgnoredata] = useState([]);

  const [data, setData] = useState([]);
  const { funds, lots, marketData, livePrices } = useSelector(
    (state) => state?.todos
  );
  const FundList = (funds?.map((i) => i?.abbreviation) || [])?.sort();
  useEffect(() => {}, [livePrices]);

  const handleFetchData = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShowSignalsTable(true);
    }, 2000);

    const data = getSecuritiesWithMarketDataRequest(lots, selectedFund);
    setData(data?.securities);

    if (data?.market_data_request && data.market_data_request.length > 0) {
      data.market_data_request.forEach((request) => {
        const socketRequest = {
          action: "market_data",
          data: request,
        };

        window.clientSocks.send(JSON.stringify(socketRequest));
      });
    }
  };

  return (
    <Container disableGutters maxWidth="100%" sx={{ marginTop: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <FormControl sx={{ width: "40%" }}>
              <Autocomplete
                options={FundList}
                getOptionLabel={(option) => option}
                onChange={(event, newValue) => {
                  setShowSignalsTable(false);
                  const myFund = findFundById(newValue, funds);
                  setSelectedFund(myFund);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Fund to Rebalance"
                    variant="standard"
                    fullWidth
                  />
                )}
              />
            </FormControl>
            {selectedFund && ignoreSecurities && (
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  color="grey"
                  startIcon={<ArrowBackIcon />}
                  // onClick={onPrevious}
                  disabled
                  sx={{ width: "150px", height: "40px" }}
                >
                  BACK
                </Button>
                <Button
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  onClick={onNext}
                  sx={{ width: "150px", height: "40px" }}
                >
                  Next
                </Button>
              </Box>
            )}
          </div>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              justifyContent: "space-between",
            }}
          >
            <RebalancingInfo fund={selectedFund} />
          </div>
        </Grid>

        {selectedFund && (
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "300px",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                onClick={handleFetchData}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "FETCH MARKET DATA"}
              </Button>
            </div>
          </Grid>
        )}

        {showSignalsTable && (
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                justifyContent: "space-between",
              }}
            >
              <SignalsLimitedByLocates
                dummy={data}
                addCol={true}
                tableHeader={TABLE_HEAD_SIGNAL_LOCATES}
                marketData={marketData}
                ignoreSecurities={ignoreSecurities}
                setIgnoreSecurities={setIgnoreSecurities}
                setIgnoredata={setIgnoredata}
              />
            </div>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default FundRebalance;
