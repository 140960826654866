import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import VantageListHead from "../../../../ListHead/SignalList";
import {
  Tablecell,
  applySortFilter,
  getComparator,
  hashInvestment,
  hashInvestmentReverse,
} from "../../../../Methods";
import MarketDataUnavailablePopup from "../../../../Popup/MarketDataUnavailablePopup";
import {
  formatTimeAgo,
  formatTimeAgo5minutes,
  getStrategiesContainingTicker,
} from "../../../../../utils/helpers";
import { updateLoading } from "../../../../../Redux/Reducers/todoReducers";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

const SignalsLimitedByLocates = ({
  dummy,
  tableHeader,
  showPopup,
  marketData,
  strategyListToggle,
  markets,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const [data, setData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("marketData");
  const [selectedRow, setSelectedRow] = useState(null);
  const [unvailableStratgies, setUnvailableStratgies] = useState([]);

  const handleRowClick = (row) => {
    if (showPopup && !row.marketData) {
      setSelectedRow(row);
      setOpenPopup(true);
      const unvailableStratgies = getStrategiesContainingTicker(
        strategyListToggle,
        row?.ticker
      );
      setUnvailableStratgies(unvailableStratgies);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    const updatedData = dummy?.map(({ ticker, ...rest }) => {
      const matchingMarketData = marketData?.find(
        (md) => md?.symbol?.toLowerCase() === ticker?.symbol?.toLowerCase()
      );

      const marketDataAvailable =
        matchingMarketData &&
        formatTimeAgo5minutes(matchingMarketData?.expiration)?.msg === "true";

      let marketName = "";

      if (markets) {
        const matchedMarket = markets.find(
          (market) => market?.marketId == matchingMarketData?.market
        );

        if (matchedMarket) {
          marketName = matchedMarket?.name;
        }
      }

      return {
        ticker: ticker.symbol,
        investment: hashInvestment(ticker.investment),
        marketData: Boolean(marketDataAvailable),
        price: matchingMarketData?.price,
        marketDataDetails: matchingMarketData,
        bloomberg: marketName,

        date: matchingMarketData?.date || null,
        ...rest,
      };
    });

    const filteredUsers = applySortFilter(
      updatedData,
      getComparator(order, orderBy)
    );

    setData(filteredUsers);
  }, [order, orderBy, dummy, marketData, markets]);

  const handleEditRow = (index, field, value) => {
    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = { ...updatedData[index], [field]: value };
      setUpdatedData((prevUpdatedData) => {
        const updatedRow = updatedData[index];
        const isAlreadyUpdated = prevUpdatedData.some(
          (item) => item.ticker === updatedRow.ticker
        );

        if (isAlreadyUpdated) {
          return prevUpdatedData.map((item) =>
            item.ticker === updatedRow.ticker ? updatedRow : item
          );
        } else {
          return [...prevUpdatedData, updatedRow];
        }
      });

      return updatedData;
    });
  };
  const handleMarketPrice = () => {
    if (markets && markets.length > 0) {
      const market = markets?.find((i) => i.type == 1);

      if (!market) {
        console.error("Market with type 1 not found");
        return;
      }

      const marketId = market.marketId;
      let keys = {};
      let values = {};

      updatedData?.forEach((item) => {
        const { ticker, price, investment } = item;

        keys[ticker] = {
          symbol: ticker,
          currency: 7,
          exchange: 4,
          investment: hashInvestmentReverse(investment),
        };

        const currentDate = Math.floor(Date.now() / 1000);

        values[ticker] = {
          [9]: {
            [marketId]: {
              date: currentDate,
              value: parseFloat(price),
            },
          },
        };
      });

      const socketRequest = {
        action: "add_market_data",
        data: {
          keys,
          values,
        },
      };
      dispatch(updateLoading(true));
      window.clientSocks.send(JSON.stringify(socketRequest));

      setTimeout(() => {
        dispatch(updateLoading(false));
        toast.success("Market Prices are Updated.");
      }, 3000);
    }
  };

  return (
    <>
      <Box
        sx={{
          border: `100px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "10px",
          width: "80%",
          marginRight: 1,
        }}
      >
        {data && data?.length > 0 ? (
          <Box
            sx={{
              maxHeight: "400px",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "5px",
                height: "3px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f0f0f0",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#c0c0c0",
                borderRadius: "10px",
                minHeight: "2px",
              },
            }}
          >
            <Typography
              color="primary"
              sx={{
                fontSize: "15px",
                textAlign: "right",
                textDecoration: "none",
                "&:hover": {
                  cursor: "pointer",
                  textDecoration: "underline",
                  textUnderlineOffset: "2px",
                },
              }}
              onClick={handleMarketPrice}
            >
              UPDATE
              {/* {ignoreSecurities
            ? "Show all securities"
            : "Ignore securities that don't have market data"} */}
            </Typography>
            <Table
              size="small"
              stickyHeader
              aria-label="a dense table"
              sx={{
                borderCollapse: "collapse",
                border: "solid 1px #eee",
                marginTop: "-1px",
              }}
            >
              <VantageListHead
                order={order}
                orderBy={orderBy}
                headLabel={tableHeader}
                noStatus={true}
                customizeFont={12}
                rowCount={data.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {data?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:hover": {
                        background: theme.palette.grey[200],
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Tablecell
                      align="left"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row.ticker}
                    </Tablecell>
                    <Tablecell
                      align="left"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row.investment}
                    </Tablecell>
                    <Tablecell
                      align="right"
                      style={{
                        fontSize: "12px",
                        paddingRight: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      <TextField
                        value={row?.price}
                        onChange={(e) =>
                          handleEditRow(index, "price", e.target.value)
                        }
                        size="small"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: "12px",
                            width: "10ch",
                            marginRight: "0.5ch",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          },
                        }}
                        inputProps={{
                          type: "number",
                          step: "any",
                          style: {
                            textAlign: "right",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            MozAppearance: "textfield", // For Firefox
                            WebkitAppearance: "none", // For most other browsers
                            // For hiding the spin buttons specifically in WebKit browsers
                            "&::-webkit-outer-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                            "&::-webkit-inner-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                          },
                        }}
                      />
                    </Tablecell>

                    <Tablecell
                      align="right"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderBottom: "none",
                        borderRight: "none",
                        display: "flex",
                        alignItems: "center",
                        color: row.marketData ? "green" : "red",
                        paddingRight: "10px",
                      }}
                      onClick={() => handleRowClick(row)}
                    >
                      {row.marketData ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between", // Ensures space between left and right
                            width: "100%",
                          }}
                        >
                          {/* Left aligned time */}
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "green",
                              textAlign: "left",
                            }}
                            variant="body2"
                          >
                            {
                              formatTimeAgo(row.marketDataDetails?.expiration)
                                ?.msg
                            }
                          </Typography>

                          {/* Right aligned Bloomberg */}
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "green",
                              textAlign: "right",
                            }}
                            variant="body2"
                          >
                            {row?.bloomberg}
                          </Typography>
                        </Box>
                      ) : (
                        <>
                          <Typography variant="body2" sx={{ fontSize: "12px" }}>
                            Not Available
                          </Typography>
                          <ErrorIcon sx={{ fontSize: "15px", mr: 0.5 }} />
                        </>
                      )}
                    </Tablecell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        ) : null}
      </Box>

      {selectedRow && (
        <MarketDataUnavailablePopup
          open={openPopup}
          onClose={() => setOpenPopup(false)}
          row={selectedRow}
          unvailableStratgies={unvailableStratgies}
        />
      )}
    </>
  );
};

export default SignalsLimitedByLocates;
