import React from "react";
import Sidebar from "./component/NavComponents/Sidebar";
// import Topbar from "./component/NavComponents/Topbar";
import SignIn from "./Pages/SignIn";
import PrivateRoute from "./component/PrivateRoute";
import Monitor from "./Pages/Monitor";
import Portfolio from "./Pages/Portfolio";
import Broker from "./Pages/Broker";
import Funds from "./Pages/Funds";
import Strategies from "./Pages/Strategies";
import Tickers from "./Pages/Tickers";
import Custodian from "./Pages/Custodian";
import TradeBlotter from "./Pages/TradeBlotter";
import VantageDashboard from "./Pages/VantageDashboard";
import Routers from "./Pages/Routers";
import DailyPerformance from "./Pages/DailyPerformace";
import Csv from "./component/CustodianDetails/Csv";
import { Routes, Route, Navigate } from "react-router-dom";
import WatchList from "./Pages/WatchList";
import AddUser from "./Pages/AddUser";
import MarketData from "./Pages/MarketData";
import Signal from "./Pages/Signal";
import Testing from "./Pages/Testing";
import Initials from "./Pages/Initials";
import FundAllocator from "./Pages/FundAllocator";
import SignalsInput from "./Pages/SignalsInput";
import Rebalancer from "./Pages/Rebalancer";
import Accounting from "./Pages/Accounting";
import SmaIntegration from "./Pages/SmaIntegration";
import SignUp from "./Pages/SignUp";
import RolesManagement from "./Pages/RolesManagement";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import TradeBlotterV2 from "./Pages/TradeBlotterV2";
import UserProfile from "./Pages/UserProfile";
import Setting from "./Pages/Setting";
import Sma from "./Pages/Sma";
import Notification from "./Pages/Notification";
import SecurityMaster from "./Pages/SecurityMaster";
import BlacklistedTickers from "./Pages/BlacklistedTickers";
import RebalancerTrades from "./Pages/RebalancerTrades";

function MainRoutes(props) {
  return (
    <>
      {!props.token ? (
        <>
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgotPass" element={<ForgotPassword />} />
            <Route path="/resetPass" element={<ResetPassword />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        </>
      ) : (
        <>
          <div className="app">
            <Sidebar />
            <main className="content">
              {props.managers && props.managers.length > 0 ? (
                <>
                  <Routes>
                    {/* <Route
                  path="*"
                  element={<Navigate to="/dashboard" replace />}
                /> */}
                    <Route
                      path="*"
                      element={<Navigate to="/vantage" replace />}
                    />
                    <Route
                      path="/monitor"
                      element={
                        <PrivateRoute>
                          <Monitor />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/market"
                      element={
                        <PrivateRoute>
                          <MarketData />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/history"
                      element={
                        <PrivateRoute>
                          <Signal />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/testing"
                      element={
                        <PrivateRoute>
                          <Testing />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/initials"
                      element={
                        <PrivateRoute>
                          <Initials />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/users"
                      element={
                        <PrivateRoute>
                          <AddUser />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/userProfile"
                      element={
                        <PrivateRoute>
                          <UserProfile />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/sma"
                      element={
                        <PrivateRoute>
                          <SmaIntegration />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/smaPerformance"
                      element={
                        <PrivateRoute>
                          <Sma />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/roles"
                      element={
                        <PrivateRoute>
                          <RolesManagement />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/portfolio"
                      element={
                        <PrivateRoute>
                          <Portfolio />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/watchlist"
                      element={
                        <PrivateRoute>
                          <WatchList />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/portfolio/:fundName"
                      element={
                        <PrivateRoute>
                          <Portfolio />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/portfolio/:fundName/:strategyName"
                      element={
                        <PrivateRoute>
                          <Portfolio />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/funds"
                      element={
                        <PrivateRoute>
                          <Funds />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/strategies"
                      element={
                        <PrivateRoute>
                          <Strategies />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/broker"
                      element={
                        <PrivateRoute>
                          <Broker />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/custodian"
                      element={
                        <PrivateRoute>
                          <Custodian />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/security"
                      element={
                        <PrivateRoute>
                          <SecurityMaster />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/blacklistedTickers"
                      element={
                        <PrivateRoute>
                          <BlacklistedTickers />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/csv"
                      element={
                        <PrivateRoute>
                          <Csv />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/routers"
                      element={
                        <PrivateRoute>
                          <Routers />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/tradesV2"
                      element={
                        <PrivateRoute>
                          <TradeBlotter />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/trades"
                      element={
                        <PrivateRoute>
                          <TradeBlotterV2 />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/rebalancerTrades"
                      element={
                        <PrivateRoute>
                          <RebalancerTrades />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/settings"
                      element={
                        <PrivateRoute>
                          <Setting />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/fundAllocator"
                      element={
                        <PrivateRoute>
                          <FundAllocator />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/signalsInput"
                      element={
                        <PrivateRoute>
                          <SignalsInput />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/rebalancer"
                      element={
                        <PrivateRoute>
                          <Rebalancer />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/accounting"
                      element={
                        <PrivateRoute>
                          <Accounting />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/tickers"
                      element={
                        <PrivateRoute>
                          <Tickers />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/notifications"
                      element={
                        <PrivateRoute>
                          <Notification />
                        </PrivateRoute>
                      }
                    />
                    {/* <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                /> */}
                    <Route
                      path="/vantage"
                      element={
                        <PrivateRoute>
                          <VantageDashboard />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/performance"
                      element={
                        <PrivateRoute>
                          <DailyPerformance />
                        </PrivateRoute>
                      }
                    />
                  </Routes>
                </>
              ) : (
                <>
                  <Routes>
                    <Route
                      path="/"
                      element={<Navigate to="/portfolio" replace />}
                    />
                    <Route
                      path="/portfolio"
                      element={
                        <PrivateRoute>
                          <Portfolio />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/tradesV2"
                      element={
                        <PrivateRoute>
                          <TradeBlotter />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/trades"
                      element={
                        <PrivateRoute>
                          <TradeBlotterV2 />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/rebalancerTrades"
                      element={
                        <PrivateRoute>
                          <RebalancerTrades />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/signalsInput"
                      element={
                        <PrivateRoute>
                          <SignalsInput />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/strategies"
                      element={
                        <PrivateRoute>
                          <Strategies />
                        </PrivateRoute>
                      }
                    />
                  </Routes>
                </>
              )}
            </main>
          </div>
        </>
      )}
    </>
  );
}

export default MainRoutes;
