import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  FormControl,
  Autocomplete,
  TextField,
  useTheme,
} from "@mui/material";
import {
  applySortFilter,
  formatValue,
  getComparator,
  performance_list,
  Tablecell,
  TradeInvestment,
} from "../Methods";
import { useSelector } from "react-redux";
import VantageListHead from "../ListHead/VantageListHead";
import MonitorScreenCalculation from "../../component/Tables/Portfolio/AssetPositions/MonitorScreenCalculation";

const PositionPeformance = (props) => {
  const theme = useTheme();
  const { calculatedMonitorScreen } = useSelector((state) => state?.todos);

  const { title, isPositionPerformance, setIsPositionPerformance } = props;
  const [filterNameBest, setFilterNameBest] = useState("");
  const [orderByBest, setOrderByBest] = useState("best_timeVal");
  const [orderBest, setOrderBest] = useState("desc");
  const [filterNameWorst, setFilterNameWorst] = useState("");
  const [orderByWorst, setOrderByWorst] = useState("worst_timeVal");
  const [orderWorst, setOrderWorst] = useState("asc");
  const [Best, setBest] = useState([]);
  const [Worst, setWorst] = useState([]);
  const [time, setTime] = useState("Daily");

  const BestdynamicColumnHeaders = [
    { id: "best_ticker", label: "Tickers", alignLeft: true },
    { id: "best_side", label: "Side", alignLeft: true },
    { id: "best_timeVal", label: `${time} PnL`, alignLeft: true },
  ];
  const WorstdynamicColumnHeaders = [
    { id: "worst_ticker", label: "Tickers", alignLeft: true },
    { id: "worst_side", label: "Side", alignLeft: true },
    { id: "worst_timeVal", label: `${time} PnL`, alignLeft: true },
  ];
  const handleRequestSort = (event, property) => {
    if (property.startsWith("best_")) {
      const isAsc = orderBest === "asc";
      setOrderBest(isAsc ? "desc" : "asc");
      setOrderByBest(property.slice(5));
    } else if (property.startsWith("worst_")) {
      const isAsc = orderWorst === "asc";
      setOrderWorst(isAsc ? "desc" : "asc");
      setOrderByWorst(property.slice(6));
    }
  };

  const getTimeArrays = (time, array = []) => {
    let key;

    if (time === "Daily") {
      key = "pL";
    } else if (time === "Weekly") {
      key = "profitLossWeek";
    } else if (time === "Monthly") {
      key = "profitLossMonth";
    } else if (time === "Quarterly") {
      key = "profitLossQuarter";
    } else if (time === "Yearly") {
      key = "profitLossYear";
    } else {
      return { best: [], worst: [] };
    }

    const data = array
      .filter(
        (item) =>
          item[key] !== undefined &&
          !isNaN(
            (item[key] && item?.tickerInvestment == TradeInvestment.Equity) ||
              item?.tickerInvestment == TradeInvestment.Options
          )
      )
      .map((item) => ({
        timeVal: parseFloat(item[key]),
        ticker: item?.tickerSymbol?.split(" ")[0],
        side: item?.deltaAdjustedShareCount,
      }));

    const sortedData = data.sort((a, b) => b.timeVal - a.timeVal);
    const top5Values = sortedData.slice(0, 5);
    const bottom5Values = sortedData.slice(-5);

    return { best: top5Values, worst: bottom5Values };
  };

  useEffect(() => {
    if (calculatedMonitorScreen && time) {
      const { best, worst } = getTimeArrays(time, calculatedMonitorScreen);
      const filteredBest =
        best.length > 0
          ? applySortFilter(
              best,
              getComparator(orderBest, orderByBest),
              filterNameBest
            )
          : [];
      const modifiedFilteredBest = filteredBest?.map((item) => ({
        best_timeVal: item.timeVal,
        best_ticker: item.ticker,
        best_side: item.side,
      }));

      setBest(modifiedFilteredBest);

      const filteredWorst =
        worst.length > 0
          ? applySortFilter(
              worst,
              getComparator(orderWorst, orderByWorst),
              filterNameWorst
            )
          : [];
      const modifiedFilteredWorst = filteredWorst?.map((item) => ({
        worst_timeVal: item.timeVal,
        worst_ticker: item.ticker,
        worst_side: item.side,
      }));

      setWorst(modifiedFilteredWorst);
    } else {
      setBest([]);
      setWorst([]);
    }
  }, [
    time,
    orderBest,
    orderByBest,
    filterNameBest,
    orderWorst,
    orderByWorst,
    filterNameWorst,
    calculatedMonitorScreen,
  ]);

  const handleClose = () => {
    setBest([]);
    setWorst([]);
    setIsPositionPerformance(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isPositionPerformance}
      onClose={handleClose}
      sx={{
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "500px",
          marginLeft: "200px",
        },
      }}
    >
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          align="center"
          style={{ flexGrow: 1, fontSize: "20px" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <Autocomplete
                disableClearable
                autoSelect
                autoHighlight
                autoComplete
                ListboxProps={{
                  sx: { fontSize: 12 },
                }}
                sx={{
                  "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                    fontSize: 12,
                  },
                }}
                value={time}
                onChange={(e, newValue) => {
                  setTime(newValue);
                }}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setTime("Daily");
                  }
                }}
                options={performance_list?.map((i) => i.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="time"
                    name="Time"
                    variant="standard"
                    value={time}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={5.5}>
              <Box
                sx={{
                  border: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                  borderRadius: "4px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: theme.palette.green.longPositions,
                    px: 1,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ color: theme.palette.lightGrey.textColor }}
                  >
                    5 Best Performing Positions
                  </Typography>
                </Box>

                <TableContainer>
                  <Table
                    size="small"
                    stickyHeader
                    aria-label="a dense table"
                    sx={{
                      height: "max-content",
                      maxHeight: "55px",
                      borderCllapse: "collapse",
                    }}
                  >
                    <VantageListHead
                      headLabel={BestdynamicColumnHeaders}
                      noStatus={true}
                      title={true}
                      order={orderBest}
                      orderBy={orderByBest}
                      onRequestSort={handleRequestSort}
                    />
                    <tbody>
                      <TableBody>
                        {Best?.map((row) => (
                          <TableRow
                            key={row.best_ticker}
                            sx={{
                              backgroundColor: theme.palette.grey[100],
                              "&:hover": {
                                background: theme.palette.grey[200],
                                cursor: "pointer",
                              },
                            }}
                          >
                            <Tablecell
                              style={{ width: "50px" }}
                              align="left"
                              sx={{ paddingRight: "2px" }}
                            >
                              {row?.best_ticker}
                            </Tablecell>
                            <Tablecell
                              style={{ width: "50px" }}
                              align="left"
                              sx={{ paddingRight: "2px" }}
                            >
                              {row?.best_side >= 0 ? "Long" : "Short"}
                            </Tablecell>

                            <Tablecell
                              align="right"
                              style={{
                                color: row?.best_timeVal >= 0 ? "green" : "red",
                                paddingRight: "2px",
                              }}
                            >
                              {row?.best_timeVal >= 0
                                ? formatValue(row?.best_timeVal)
                                : `(${formatValue(row?.best_timeVal)})`}
                            </Tablecell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </tbody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>

            <Grid item xs={5.5}>
              <Box
                sx={{
                  border: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                  borderRadius: "4px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: theme.palette.red.shortPositions,
                    px: 1,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ color: theme.palette.lightGrey.textColor }}
                  >
                    5 Worst Performing Positions
                  </Typography>
                </Box>

                <TableContainer>
                  <Table
                    size="small"
                    stickyHeader
                    aria-label="a dense table"
                    sx={{
                      height: "max-content",
                      maxHeight: "55px",
                      borderCllapse: "collapse",
                    }}
                  >
                    <VantageListHead
                      headLabel={WorstdynamicColumnHeaders}
                      noStatus={true}
                      title={true}
                      order={orderWorst}
                      orderBy={orderByWorst}
                      onRequestSort={handleRequestSort}
                    />
                    <tbody>
                      <TableBody>
                        {Worst?.map((row) => (
                          <TableRow
                            key={row.worst_ticker}
                            sx={{
                              backgroundColor: theme.palette.grey[100],
                              "&:hover": {
                                background: theme.palette.grey[200],
                                cursor: "pointer",
                              },
                            }}
                          >
                            <Tablecell
                              style={{ width: "50px" }}
                              align="left"
                              sx={{ paddingRight: "2px" }}
                            >
                              {row?.worst_ticker}
                            </Tablecell>
                            <Tablecell
                              style={{ width: "50px" }}
                              align="left"
                              sx={{ paddingRight: "2px" }}
                            >
                              {row?.worst_side >= 0 ? "Long" : "Short"}
                            </Tablecell>
                            <Tablecell
                              align="right"
                              style={{
                                color:
                                  row?.worst_timeVal >= 0 ? "green" : "red",
                                paddingRight: "2px",
                              }}
                            >
                              {row?.worst_timeVal >= 0
                                ? formatValue(row?.worst_timeVal)
                                : `(${formatValue(row?.worst_timeVal)})`}
                            </Tablecell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </tbody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PositionPeformance;
