import React, { useState, useEffect } from "react";
import {
  Card,
  Container,
  Typography,
  TableContainer,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tradesData } from "../../Methods";
import Header from "../../Header";
import { DeletedTradescolumns } from "../../Methods/columnHeader";
import { DataGridPro } from "@mui/x-data-grid-pro";
import DetailPanelContent from "./DetailPanelContent";

const TradeBlotterTable = ({ mydate, prioritySwitch }) => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [dateChange, setDateChange] = useState(mydate);
  const [updatedArray, setUpdatedArray] = useState([]);
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(100);

  const { deletes, funds, custodians, routeList, strategies } = useSelector(
    (state) => state?.todos
  );
  const brokers = useSelector((state) => state?.todos?.data);
  const getDetailPanelHeight = React.useCallback(() => 400, []);

  useEffect(() => {
    if (deletes) {
      const SimpleArray = tradesData(
        deletes,
        funds,
        brokers,
        routeList,
        strategies,
        custodians,
        prioritySwitch
      );
      const filteredData = SimpleArray?.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setUpdatedArray(filteredData);
    }
  }, [deletes]);

  useEffect(() => {
    if (deletes && updatedArray) {
      const filteredData = updatedArray
        .filter(
          (row) =>
            row?.TradeTime > dateChange?.start &&
            row?.TradeTime < dateChange?.end
        )
        ?.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
      setData(filteredData);
    }
  }, [deletes, updatedArray, dateChange]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setPage(0);
    setRowsPerPage(pageSize);
  };
  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    []
  );
  return (
    <>
      <Container maxWidth="70%">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              {" "}
              <div style={{ marginLeft: "-25px", marginTop: "-20px" }}>
                <Header title="Deleted Trades" />
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ marginLeft: "-25px" }}></div>

            <Card>
              <TableContainer style={{ height: "65vh", minHeight: "65vh" }}>
                <DataGridPro
                  columns={DeletedTradescolumns(theme)}
                  rows={data}
                  rowThreshold={0}
                  getDetailPanelHeight={getDetailPanelHeight}
                  getDetailPanelContent={getDetailPanelContent}
                  height={300}
                  rowHeight={15}
                  checkboxSelection
                  pageSize={100}
                  rowsPerPageOptions={[2000,5000,10000]}
                  page={page}
                  onPageChange={handleChangePage}
                  onPageSizeChange={handleChangeRowsPerPage}
                  density="standard"
                  sx={{
                    "& .MuiDataGrid-sortIcon": {
                      display: "none",
                    },
                    "& .MuiDataGrid-row": {
                      padding: "0px",
                      fontSize: "12px",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      color: theme.palette.grey[100],
                      background: theme.palette.grey[600],
                      fontSize: "11px",
                      padding: "0px",
                    },
                    "& .MuiDataGrid-iconSeparator": {
                      display: "none",
                    },
                  }}
                />
              </TableContainer>
            </Card>
          </AccordionDetails>
        </Accordion>
      </Container>
    </>
  );
};
export default TradeBlotterTable;
