import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Spinner } from "../../spinner";
import CloseIcon from "@mui/icons-material/Close";
import { getLoading } from "../../../Redux/Reducers/todoReducers";

export default function PopUp(props) {
  const { id, children, openPopup, onClose, title, showIcon } = props;
  const loading = useSelector(getLoading);

  return (
    <Dialog
      open={openPopup}
      sx={{ height: "100%" }}
      maxWidth={
        title === "ADD SIGNAL"
          ? "md"
          : title === "Add Account Details"
          ? "xs"
          : "sm"
      }
      onClose={onClose} // Using the onClose prop here
    >
      {loading && (
        <Box marginLeft={-50}>
          <Spinner />
        </Box>
      )}
      {title === "Trade Entry Form" ? (
        <DialogTitle
          sx={{
            height: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            style={{ marginTop: "-11px", fontSize: "18px" }}
          >
            {title}
          </Typography>
          <IconButton
            sx={{ marginTop: "-13px", width: "25px", height: "25px" }}
            onClick={onClose} // Close the popup using the passed onClose
          >
            <CloseIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </DialogTitle>
      ) : !showIcon && title ? (
        <DialogTitle>
          <div style={{ display: "flex" }}>
            <Typography
              variant="h6"
              component="div"
              style={{ flexGrow: 1, fontSize: "20px" }}
            >
              {title}
            </Typography>
            <IconButton onClick={onClose}> {/* Close button uses the onClose function */}
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
      ) : (
        <></>
      )}
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}
