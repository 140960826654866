function formatTimeAgo(dateString) {
  if (!isValidDate(dateString)) {
    return { valid: false, msg: "N/A" };
  }
  const currentDate = new Date();
  const givenDate = new Date(parseInt(dateString, 10) * 1000);

  const timeDifference = currentDate - givenDate;
  const threshold = localStorage.getItem("threshold");
  const minutesDifference = Math.floor(timeDifference / (1000 * 60));

  let toreturn = { valid: true };
  if (minutesDifference > parseFloat(threshold)) {
    toreturn.valid = false;
  }

  if (minutesDifference < 1) {
    toreturn.msg = "just now";
    return toreturn;
  } else if (minutesDifference === 1) {
    toreturn.msg = "1 min ago";
    return toreturn;
  } else if (minutesDifference < 60) {
    toreturn.msg = `${minutesDifference} min ago`;
    return toreturn;
  } else {
    const hoursDifference = Math.floor(minutesDifference / 60);

    if (hoursDifference === 1) {
      toreturn.msg = "1 hour ago";
      return toreturn;
    } else if (hoursDifference < 24) {
      toreturn.msg = `${hoursDifference} hours ago`;
      return toreturn;
    } else {
      const daysDifference = Math.floor(hoursDifference / 24);

      if (daysDifference === 1) {
        toreturn.msg = "1 day ago";
        return toreturn;
      } else {
        toreturn.msg = `${daysDifference} days ago`;
        return toreturn;
      }
    }
  }
}
function formatTimeAgo5minutes(dateString) {
  if (!isValidDate(dateString)) {
    // return { valid: false, msg: "N/A" };
    return { valid: true, msg: "true" };
  }

  const currentDate = new Date();
  const givenDate = new Date(parseInt(dateString, 10) * 1000);
  const timeDifference = currentDate - givenDate;
  const minutesDifference = Math.floor(timeDifference / (1000 * 60));

  if (minutesDifference <= 5) {
    return { valid: true, msg: "true" };
  } else {
    // return { valid: true, msg: "false" };
    return { valid: true, msg: "true" };
  }
}

function isValidDate(dateString) {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

function capitalizeFirstLetters(str) {
  return str.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
}

function getSecuritiesWithMarketDataRequest(
  lots,
  fundId,
  strategyIds = [],
  strategies = []
) {
  const filteredLots = lots.filter((lot) => {
    const fundMatches = lot.fund === fundId;
    const strategyMatches =
      strategyIds.length === 0 || strategyIds?.includes(lot.strategy);
    return fundMatches && strategyMatches;
  });

  const signalSets = strategies
    .filter((strategy) => strategyIds?.includes(strategy?.strategyId))
    .map((strategy) => strategy?.signals);
  const tickerMap = {};

  filteredLots?.forEach((lot) => {
    const { ticker } = lot;
    const symbol = ticker.symbol;
    const investmentType =
      ticker?.investment === 4
        ? "equity"
        : ticker?.investment === 9
        ? "options"
        : null;

    if (!tickerMap[symbol]) {
      tickerMap[symbol] = {
        ticker: ticker,
        type: investmentType,
      };
    } else {
      if (tickerMap[symbol].type !== investmentType && investmentType) {
        tickerMap[symbol].type = "equity & options";
      }
    }
  });

  signalSets.forEach((signalSet) => {
    const signalTickers = signalSet?.weights_keys;

    for (const key in signalTickers) {
      const { symbol, investment } = signalTickers[key];
      const investmentType =
        investment === 4 ? "equity" : investment === 9 ? "options" : null;

      if (!tickerMap[symbol]) {
        tickerMap[symbol] = {
          ticker: signalTickers[key],
          type: investmentType,
        };
      } else {
        if (tickerMap[symbol].type !== investmentType && investmentType) {
          tickerMap[symbol].type = "equity & options";
        }
      }
    }
  });

  const securities = Object.values(tickerMap);
  const groupedRequests = {
    equity: { threshold: 3600, request: true, fields: [9, 10], tickers: [] },
    options: { threshold: 3600, request: true, fields: [11], tickers: [] },
    "equity & options": {
      threshold: 3600,
      request: true,
      fields: [9, 10, 11],
      tickers: [],
    },
  };

  securities.forEach((security) => {
    if (groupedRequests[security.type]) {
      groupedRequests[security.type].tickers.push(security.ticker);
    }
  });

  const market_data_request = Object.values(groupedRequests).filter(
    (request) => request.tickers.length > 0
  );

  return {
    securities: securities,
    market_data_request: market_data_request,
  };
}

function getStrategiesContainingTicker(strategies, tickerSymbol) {
  const foundInStrategies = [];

  strategies?.forEach((strategy) => {
    const signalSet = strategy?.signals?.weights_keys;

    if (signalSet && typeof signalSet === "object") {
      const tickerExists = Object.values(signalSet).some(
        (signalTicker) =>
          signalTicker?.symbol?.toLowerCase() === tickerSymbol?.toLowerCase()
      );

      if (tickerExists) {
        foundInStrategies.push(strategy.name);
      }
    }
  });

  return foundInStrategies;
}

export {
  formatTimeAgo,
  formatTimeAgo5minutes,
  capitalizeFirstLetters,
  getSecuritiesWithMarketDataRequest,
  getStrategiesContainingTicker,
};
