import React from "react";
import Topbar from "../component/NavComponents/Topbar";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import RebalancedTrades from "../component/Layouts/RebalancedTrades";

const RebalancerTrades = () => {
  return (
    <>
      <Helmet>
        <title>Rebalancer Trades</title>
      </Helmet>
      <Topbar title="Rebalancer Trades" />
      <RebalancedTrades />
      <Footer />
    </>
  );
};
export default RebalancerTrades;
