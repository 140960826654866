import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import VantageListHead from "../../../../../../ListHead/SignalList";
import {
  Tablecell,
  applySortFilter,
  calculateAndFormatNoAbsInt,
  findStrategy,
  formatValue,
  getComparator,
  hashAction,
  hashPosition,
} from "../../../../../../Methods";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";

const CrossingTradesTable = ({ tableHeader, crossingTrades }) => {
  const theme = useTheme();
  const { strategies } = useSelector((state) => state.todos);
  const [openPopup, setOpenPopup] = useState(false);
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ticker");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  useEffect(() => {
    const filteredOrders = crossingTrades?.map((order) => {
      const strategy = findStrategy(order?.strategy, strategies);
      const ticker = order?.ticker?.symbol;
      let action = hashAction(order?.action) + hashPosition(order?.position);
      const shareCount = order?.shareCount;
      const custodian = order?.custodian;

      if (action === "BuyShort") {
        action = "CoverShort";
      }

      return {
        strategy: strategy,
        ticker: ticker,
        custodian: custodian,
        action: action,
        shareCount: shareCount,
      };
    });

    const filteredUsers = applySortFilter(
      filteredOrders,
      getComparator(order, orderBy)
    );
    setData(filteredUsers);
  }, [order, orderBy, crossingTrades]);
  const deleteActivity = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };

  // Group data by ticker
  const groupedData = data.reduce((acc, row) => {
    if (!acc[row.ticker]) {
      acc[row.ticker] = [];
    }
    acc[row.ticker].push(row);
    return acc;
  }, {});

  // Convert grouped data to an array and assign alternating colors only to groups with more than one row
  const rowsWithColors = Object.entries(groupedData).reduce(
    (acc, [ticker, rows], index) => {
      const color =
        rows.length > 1
          ? index % 2 === 0
            ? theme.palette.grey[200]
            : "#d9d9d9"
          : "white";
      rows.forEach((row) => {
        acc.push({ ...row, color });
      });
      return acc;
    },
    []
  );

  return (
    <>
      <Box
        sx={{
          border: `100px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "1px",
          width: "100%",
          marginRight: 1,
        }}
      >
        <Box
          sx={{
            maxHeight: "650px",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "5px",
              height: "3px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f0f0f0",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#c0c0c0",
              borderRadius: "10px",
              minHeight: "2px",
            },
          }}
        >
          <Box
            sx={{
              maxHeight: "650px",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "5px",
                height: "3px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f0f0f0",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#c0c0c0",
                borderRadius: "10px",
                minHeight: "2px",
              },
            }}
          >
            <Table
              size="small"
              stickyHeader
              aria-label="a dense table"
              sx={{
                borderCollapse: "collapse",
                border: "solid 1px #eee",
                marginTop: "-1px",
              }}
            >
              <VantageListHead
                order={order}
                orderBy={orderBy}
                headLabel={tableHeader}
                noStatus={true}
                customizeFont={12}
                rowCount={data.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {rowsWithColors.map((row, index) => (
                  <TableRow
                    key={`${row.strategy}-${row.ticker}-${row.groupIndex}-${row.rowIndex}`}
                    sx={{
                      backgroundColor: row.color,
                      "& > .MuiTableCell-root": {
                        paddingTop: "1px",
                        paddingBottom: "0px !important",
                        lineHeight: "1.2",
                        "& .MuiInputBase-input": {
                          padding: "0px 0px !important",
                        },
                        "& .MuiIconButton-root": {
                          padding: "0px",
                        },
                      },
                      "&:hover": {
                        background: theme.palette.primary[100],
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Tablecell
                      align="left"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row.strategy}
                    </Tablecell>
                    <Tablecell
                      align="left"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row?.custodian}
                    </Tablecell>
                    <Tablecell
                      align="left"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row?.action}
                    </Tablecell>
                    <Tablecell
                      align="left"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row?.ticker}
                    </Tablecell>
                    <Tablecell
                      align="right"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row?.shareCount !== undefined && row?.shareCount != null
                        ? row?.shareCount < 0
                          ? `(${Math.abs(row?.shareCount)})`
                          : `${calculateAndFormatNoAbsInt(row?.shareCount)}`
                        : ""}
                    </Tablecell>

                    {/* <Tablecell
                      align="center"
                      sx={{
                        borderRight: "none",
                      }}
                    >
                      <IconButton
                        onClick={() => deleteActivity(index)}
                        aria-label="delete"
                        sx={{
                          height: "15px",
                          width: "15px",
                        }}
                      >
                        <DeleteIcon sx={{ height: "15px", width: "15px" }} />
                      </IconButton>
                    </Tablecell> */}
                  </TableRow>
                ))}
              </TableBody>{" "}
            </Table>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CrossingTradesTable;
