import { Box } from "@mui/material";
import React from "react";
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';



export const Reconnect = (props) => {
  

  return (
    <Box sx={{position:"fixed",width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center", backdropFilter: 'blur(5px)', zIndex:3 }}>



<Box sx={{ width: 500 }}>

      <Snackbar
        anchorOrigin={{ vertical:"top", horizontal:"center" }}
        open={true}
        message="Connection Lost, Reconnecting..."
      />
    </Box>

    </Box>
    
  );
};

