import React, { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  useTheme,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditRuleDialog from "./EditRuleDialog"; // Import the EditRuleDialog component
import {
  findStrategyById,
  hashGetBlacklistCritera,
  hashGetBlacklistType,
} from "../../Methods";
import { updateLoading } from "../../../Redux/Reducers/todoReducers";

import { useDispatch } from "react-redux";

const RulesForm = ({
  Strategies,
  BlacklistData,
  recordForBlacklist,
  setBlacklistOpenPopup,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRule, setSelectedRule] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editedRule, setEditedRule] = useState(null);
  const [title, setTitle] = useState("Add Rule");

  const handleMenuClick = (event, rule) => {
    setAnchorEl(event.currentTarget);
    setSelectedRule(rule);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRule(null);
  };

  const handleEdit = () => {
    setTitle("Edit Rule");
    setEditedRule(selectedRule);
    setOpenEditDialog(true);
    handleMenuClose();
  };

  const handleDelete = () => {
    const ruleObj = {
      type: selectedRule.type,
    };

    const data = {
      action: "modify_blacklist",
      data: {
        rules: {
          add: [],
          modify: [],
          remove: [],
        },
      },
    };

    const tickers = selectedRule.tickers || [];
    if (tickers.length == 0) {
      data.data.rules.remove.push({
        ...(selectedRule.strategy && { strategy: selectedRule.strategy }),
        ...ruleObj,
      });
    }
    tickers.forEach((ticker) => {
      data.data.rules.remove.push({
        ...(selectedRule.strategy && { strategy: selectedRule.strategy }),
        ticker: ticker,
        ...ruleObj,
      });
    });

    data.data.fundId = BlacklistData.fundId;

    window.clientSocks.send(JSON.stringify(data));

    setBlacklistOpenPopup(false);
    dispatch(updateLoading(true));
    handleMenuClose();
  };
  const handleAdd = () => {
    setEditedRule(null);
    setTitle("Add Rule");
    setOpenEditDialog(true);
  };

  const handleDialogClose = () => {
    setOpenEditDialog(false);
    setEditedRule(null);
  };

  const handleSaveEdit = (updatedRule) => {
    // Add the logic to update the rules state with the edited rule.
    setOpenEditDialog(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedRule((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getOperator = (type, maximum, minimum) => {
    if (maximum && minimum && maximum === minimum) {
      return "=";
    }
    if (maximum) {
      return ">";
    }
    if (minimum) {
      return "<";
    }
    return "";
  };

  return (
    <>
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: "20px",
          color: "#000",
          mb: 2,
        }}
      >
        Blackist Rules
      </Typography>
      <Box
        sx={{
          maxHeight: "320px",
          overflowY: "auto",
          width: "100%",
        }}
      >
        <Grid container spacing={1}>
          {BlacklistData?.rules?.map((rule, ruleIndex) => {
            const { type, maximum, minimum, strategy, tickers } = rule;
            const Strategy = strategy ? strategy.name : "All";
            const Ticker = tickers
              ? tickers.map((ticker) => ticker).join(", ")
              : "All";
            const operator = getOperator(type, maximum, minimum);

            let ruleText = hashGetBlacklistCritera(type);
            // if (operator) {
            //   ruleText += ` ${operator} ${maximum || minimum}`;
            // }
            let msg = "";
            if (ruleText == "NotAllowed") {
              msg = "These tickers are not allowed to be traded";
            }
            if (
              ruleText == "Price" ||
              ruleText == "Allocation" ||
              ruleText == "MarketCap" ||
              ruleText == "Shares"
            ) {
              if (operator == ">") {
                msg =
                  "Stocks with " +
                  ruleText.toLowerCase() +
                  " greater than " +
                  maximum +
                  " are not allowed";
              }
              if (operator == "<") {
                msg =
                  "Stocks with " +
                  ruleText.toLowerCase() +
                  " less than " +
                  minimum +
                  " are not allowed";
              }
              if (operator == "=") {
                msg =
                  "Stocks with " +
                  ruleText.toLowerCase() +
                  " equal to " +
                  maximum +
                  " are not allowed";
              }
            }

            return (
              <Grid
                container
                alignItems="center"
                key={ruleIndex}
                sx={{
                  padding: 2,
                  borderRadius: "8px",
                  marginBottom: 2,
                  backgroundColor: "#eee",
                  marginBottom: "3px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#000",
                    }}
                  >
                    {msg}
                  </Typography>
                  <IconButton onClick={(event) => handleMenuClick(event, rule)}>
                    <MoreVertIcon />
                  </IconButton>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: "-12px" }}>
                  <Typography
                    sx={{
                      color: "#333",
                      fontSize: "14px",
                    }}
                  >
                    Strategy:{" "}
                    <span
                      style={{
                        fontWeight: "600",
                        color: "#333",
                      }}
                    >
                      {Strategy}
                    </span>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: "#333",
                      fontSize: "14px",
                    }}
                  >
                    Ticker:{" "}
                    <span
                      style={{
                        fontWeight: "600",
                        color: "#333",
                      }}
                    >
                      {Ticker}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAdd}
        sx={{ marginTop: "20px", float: "right" }}
      >
        Add Rule
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            width: "150px",
          },
        }}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>

      <EditRuleDialog
        open={openEditDialog}
        editedRule={editedRule}
        strategies={Strategies}
        onClose={handleDialogClose}
        onSave={handleSaveEdit}
        onChange={handleChange}
        BlacklistData={BlacklistData}
        recordForBlacklist={recordForBlacklist}
        setBlacklistOpenPopup={setBlacklistOpenPopup}
        title={title}
      />
    </>
  );
};

export default RulesForm;
