import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  TableCell,
  TextField,
  Typography,
  Box,
  Checkbox,
  NativeSelect,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import {
  TABLE_HEAD_FUNDALLOCATOR,
  Tablecell,
  applySortFilter,
  calculateAndFormatNoAbs,
  getComparator,
  twoDecimal,
} from "../../../Methods";
import ListHead from "../../../ListHead/SignalList";

const TargetAllocation = ({
  custodians,
  sortedData,
  matchedAllocations,
  setMatchedAllocations,
  updateTotals,
  fundAum,
  setPieDataTarget,
  editableValue,
}) => {
  const theme = useTheme();
  const [editableData, setEditableData] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("allocation");

  const changeSourceRef = useRef(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  useEffect(() => {
    console.log("matchedAllocations", matchedAllocations);
    setEditableData(matchedAllocations);
  }, [matchedAllocations]);

  const handleEditRow = (index, field, value, source) => {
    // if (changeSourceRef.current === source) {
    //   return; // Prevent re-triggering if the change is from the same source
    // }
    changeSourceRef.current = source;

    let targetValue = 0;
    if (typeof value == "string") {
      targetValue = parseFloat(value.replace(/,/g, "")) || 0;
    } else if (typeof value === "number") {
      targetValue = value;
    }

    setEditableData((prevData) => {
      if (!prevData) return prevData;

      const updatedData = [...prevData];

      if (field == "allocation") {
        const totalAllocationValue = (targetValue / fundAum) * 100;
        updatedData[index] = {
          ...updatedData[index],
          [field]: value,
          totalAllocation: twoDecimal(totalAllocationValue),
        };
      } else if (field == "totalAllocation") {
        console.log("inside");
        const newAllocationValue =
          parseFloat(editableValue) * parseFloat(targetValue);
        updatedData[index] = {
          ...updatedData[index],
          [field]: value,
          allocation: twoDecimal(newAllocationValue),
        };
      } else {
        updatedData[index] = { ...updatedData[index], [field]: value };
      }
      changeSourceRef.current = null;

      return updatedData;
    });

    setMatchedAllocations((prevAllocations) => {
      const updatedAllocations = [...prevAllocations];
      updatedAllocations[index] = {
        ...updatedAllocations[index],
        [field]: value,
      };
      updateTotals(updatedAllocations);
      const pieTarget = updatedAllocations.map((strategy) => ({
        name: strategy.name,
        value: Number(strategy.totalAllocation) || 0,
        color: strategy?.color,
      }));
      setPieDataTarget(pieTarget);
      return updatedAllocations;
    });
  };

  return (
    <>
      <Box
        sx={{
          border: `1px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "10px",
          width: "100%",
          height: "120px",
          marginRight: 1,
        }}
      >
        <Table
          stickyHeader
          aria-label="a dense table"
          sx={{
            height: "max-content",
            borderCollapse: "collapse",
            border: "solid 1px #eee",
          }}
        >
          <TableRow
            sx={{
              "&:hover": {
                background: theme.palette.grey[200],
                cursor: "pointer",
              },
            }}
          >
            <TableCell
              align="left"
              sx={{
                width: "140px",
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: 900,
                color: "#fff",
                padding: 0,
                paddingLeft: "20px",
                textAlign: "left",
              }}
            ></TableCell>
            <TableCell
              align="center"
              sx={{
                width: "530px",
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: 900,
                backgroundColor: "#B5D0D9",
                padding: 0,
                paddingLeft: "20px",
                textAlign: "center",
              }}
            >
              Existing
            </TableCell>
            <Tablecell
              align="center"
              sx={{
                width: "665px",
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: 900,
                backgroundColor: "#E4C0C6",
                padding: 0,
                paddingLeft: "20px",
                textAlign: "center",
              }}
            >
              Target
            </Tablecell>
            <Tablecell
              align="center"
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: 900,
                backgroundColor: "#bfffbf",
                padding: 0,
                paddingLeft: "20px",
                textAlign: "center",
              }}
            >
              Preferences
            </Tablecell>
          </TableRow>
        </Table>
        <Table
          stickyHeader
          aria-label="a dense table"
          sx={{
            height: "max-content",
            borderCollapse: "collapse",
            border: "solid 1px #eee",
          }}
        >
          <ListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD_FUNDALLOCATOR}
            onRequestSort={handleRequestSort}
            noStatus={true}
            noArrow={true}
          />
        </Table>
        <Table
          size="small"
          stickyHeader
          aria-label="a dense table"
          sx={{
            borderCollapse: "collapse",
            border: "solid 1px #eee",
            marginTop: "-1px",
          }}
        >
          <TableBody>
            {editableData &&
              editableData
                ?.sort((a, b) => b.existingPercent - a.existingPercent)
                ?.map((row, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        overflow: "auto",
                        "&:hover": {
                          background: theme.palette.grey[200],
                          cursor: "pointer",
                        },
                        "& > .MuiTableCell-root": {
                          paddingTop: "0px",
                          paddingBottom: "0px !important",
                          lineHeight: "1.2",
                          "& .MuiInputBase-input": {
                            padding: "0px 0px !important",
                          },
                          "& .MuiIconButton-root": {
                            padding: "0px",
                          },
                        },
                      }}
                      tabIndex={-1}
                      role="checkbox"
                    >
                      <TableCell
                        align="left"
                        sx={{
                          paddingLeft: "5px",
                          width: "100px",
                          fontSize: "12px",
                          padding: "0px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "5px",
                          }}
                        >
                          <span>{row?.name}</span>
                          <div
                            style={{
                              height: "10px",
                              width: "10px",
                              background: row?.color,
                            }}
                          ></div>{" "}
                        </div>
                      </TableCell>

                      <TableCell
                        align="right"
                        sx={{
                          paddingLeft: "5px",
                          width: "100px",
                          fontSize: "12px",
                          padding: "0px",
                        }}
                      >
                        {row?.existingPercent
                          ? twoDecimal(row.existingPercent)
                          : "0.00"}
                        %
                      </TableCell>

                      <TableCell
                        align="right"
                        sx={{
                          paddingLeft: "10px",
                          width: "100px",
                          fontSize: "12px",
                          padding: "0px",
                        }}
                      >
                        {row?.existingAllocation
                          ? calculateAndFormatNoAbs(row?.existingAllocation)
                          : "0"}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          paddingLeft: "5px",
                          width: "90px",
                          fontSize: "12px",
                          padding: "0px",
                        }}
                      >
                        {row?.long ? twoDecimal(row.long) : "0.00"}%
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          paddingLeft: "5px",
                          width: "90px",
                          fontSize: "12px",
                          padding: "0px",
                        }}
                      >
                        {row?.short ? twoDecimal(row.short) : "0.00"}%
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          paddingLeft: "5px",
                          width: "90px",
                          "& .MuiCheckbox-root": { padding: "0px" },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginLeft: "25px",
                          }}
                        >
                          <Checkbox
                            defaultChecked
                            checked={row?.ignoreWhenRebalancing}
                            size="small"
                            onChange={(e) =>
                              handleEditRow(
                                index,
                                "ignoreWhenRebalancing",
                                e.target.checked,
                                "ignoreWhenRebalancing"
                              )
                            }
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 10 },
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "12px",
                              marginLeft: "5px",
                              textTransform: "uppercase",
                              border: "none",
                            }}
                          >
                            {String(row?.ignoreWhenRebalancing)}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell
                        align="right"
                        sx={{
                          paddingLeft: "5px",
                          width: "120px",
                          textAlign: "right",
                          fontSize: "12px",
                        }}
                      >
                        {isNaN(
                          (parseFloat(row?.totalAllocation) / 100) *
                            parseFloat(editableValue)
                        )
                          ? "0"
                          : calculateAndFormatNoAbs(
                              (parseFloat(row?.totalAllocation) / 100) *
                                parseFloat(editableValue)
                            )}
                        {/* <TextField
                        value={calculateAndFormatNoAbs(parseFloat(row?.totalAllocation)/100 * parseFloat(editableValue))}
                        onChange={(e) => {
                          const value = e.target.value;
                          handleEditRow(index, "allocation", value, "allocation");
                        }}
                        size="small"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: "12px",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          },
                        }}
                        inputProps={{
                          step: "any",
                          style: {
                            textAlign: "right",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          },
                        }}
                      /> */}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderLeft: "none",
                          width: "100px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                            }}
                          >
                            <TextField
                              value={row?.totalAllocation}
                              onChange={(e) => {
                                const value = e.target.value;

                                const formattedValue =
                                  value.match(/^\d*(\.\d{0,2})?/);

                                if (
                                  formattedValue &&
                                  !isNaN(formattedValue[0])
                                ) {
                                  handleEditRow(
                                    index,
                                    "totalAllocation",
                                    formattedValue[0],
                                    "totalAllocation"
                                  );
                                }
                              }}
                              size="small"
                              variant="standard"
                              InputProps={{
                                disableUnderline: true,
                                style: {
                                  fontSize: "12px",
                                  width: "40px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                },
                              }}
                              inputProps={{
                                step: "any",
                                style: {
                                  textAlign: "right",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                  MozAppearance: "textfield", // For Firefox
                                  WebkitAppearance: "none", // For most other browsers
                                  "&::-webkit-outer-spin-button": {
                                    WebkitAppearance: "none",
                                    margin: 0,
                                  },
                                  "&::-webkit-inner-spin-button": {
                                    WebkitAppearance: "none",
                                    margin: 0,
                                  },
                                },
                              }}
                            />
                            <Box
                              sx={{
                                fontSize: "11px",
                                width: "12px",
                                height: "15px",
                                mt: "3px",
                                mr: 1,
                              }}
                            >
                              %
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          borderLeft: "none",
                          width: "100px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                            }}
                          >
                            <TextField
                              value={row?.percentLong}
                              onChange={(e) => {
                                const value = e.target.value;

                                const formattedValue =
                                  value.match(/^\d*(\.\d{0,2})?/);

                                if (
                                  formattedValue &&
                                  !isNaN(formattedValue[0])
                                ) {
                                  handleEditRow(
                                    index,
                                    "percentLong",
                                    formattedValue[0],
                                    "percentLong"
                                  );
                                }
                              }}
                              size="small"
                              variant="standard"
                              InputProps={{
                                disableUnderline: true,
                                style: {
                                  fontSize: "12px",
                                  width: "40px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                },
                              }}
                              inputProps={{
                                step: "any",
                                style: {
                                  textAlign: "right",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                  MozAppearance: "textfield", // For Firefox
                                  WebkitAppearance: "none", // For most other browsers
                                  "&::-webkit-outer-spin-button": {
                                    WebkitAppearance: "none",
                                    margin: 0,
                                  },
                                  "&::-webkit-inner-spin-button": {
                                    WebkitAppearance: "none",
                                    margin: 0,
                                  },
                                },
                              }}
                            />
                            <Box
                              sx={{
                                fontSize: "11px",
                                width: "12px",
                                height: "15px",
                                mt: "3px",
                                mr: 1,
                              }}
                            >
                              %
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderLeft: "none",
                          width: "100px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                            }}
                          >
                            <TextField
                              value={row?.percentShort}
                              onChange={(e) => {
                                const value = e.target.value;

                                const formattedValue =
                                  value.match(/^\d*(\.\d{0,2})?/);

                                if (
                                  formattedValue &&
                                  !isNaN(formattedValue[0])
                                ) {
                                  handleEditRow(
                                    index,
                                    "percentShort",
                                    formattedValue[0],
                                    "percentShort"
                                  );
                                }
                              }}
                              size="small"
                              variant="standard"
                              InputProps={{
                                disableUnderline: true,
                                style: {
                                  fontSize: "12px",
                                  width: "40px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                },
                              }}
                              inputProps={{
                                step: "any",
                                style: {
                                  textAlign: "right",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                  MozAppearance: "textfield", // For Firefox
                                  WebkitAppearance: "none", // For most other browsers
                                  "&::-webkit-outer-spin-button": {
                                    WebkitAppearance: "none",
                                    margin: 0,
                                  },
                                  "&::-webkit-inner-spin-button": {
                                    WebkitAppearance: "none",
                                    margin: 0,
                                  },
                                },
                              }}
                            />
                            <Box
                              sx={{
                                fontSize: "11px",
                                width: "12px",
                                height: "15px",
                                mt: "3px",
                                mr: 1,
                              }}
                            >
                              %
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          paddingLeft: "5px",
                          width: "80px",
                          "& .MuiNativeSelect-icon": { display: "none" },
                        }}
                      >
                        <NativeSelect
                          value={row.broker}
                          onChange={(e) =>
                            handleEditRow(
                              index,
                              "broker",
                              e.target.value,
                              "broker"
                            )
                          }
                          autoSelect
                          disableUnderline
                          sx={{ fontSize: "12px" }}
                          id={`broker-${index}`}
                          name={`broker-${index}`}
                        >
                          {sortedData?.map((val) => (
                            <option key={val.brokerId} value={val.brokerId}>
                              {val.abbreviation}
                            </option>
                          ))}
                        </NativeSelect>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          paddingLeft: "5px",
                          width: "80px",
                          "& .MuiNativeSelect-icon": { display: "none" },
                        }}
                      >
                        <NativeSelect
                          value={row.account}
                          onChange={(e) =>
                            handleEditRow(
                              index,
                              "account",
                              e.target.value,
                              "account"
                            )
                          }
                          autoSelect
                          disableUnderline
                          sx={{ fontSize: "12px" }}
                          id={`custodian-${index}`}
                          name={`custodian-${index}`}
                        >
                          {custodians?.map((val) => (
                            <option key={val.custodian} value={val.name}>
                              {val.name}
                            </option>
                          ))}
                        </NativeSelect>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default TargetAllocation;
