import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  TextField,
  TableCell,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import VantageListHead from "../../../../../../ListHead/SignalList";
import {
  Tablecell,
  applySortFilter,
  formatValue,
  getComparator,
} from "../../../../../../Methods";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const Locates = ({ tickerTrades, setLocatesData, tableHeader, custodian }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState(tickerTrades);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ticker");

  useEffect(() => {
    const filteredData = applySortFilter(
      tickerTrades,
      getComparator(order, orderBy)
    );
    setData(filteredData);
  }, [order, orderBy, tickerTrades]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleAvailableChange = (e, index, ticker) => {
    const { value } = e.target;
    const updatedTickerTrades = tickerTrades.map((trade) =>
      trade.ticker === ticker ? { ...trade, available: Number(value) } : trade
    );

    setLocatesData(updatedTickerTrades, custodian);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    if (action === "Copy") {
      const formattedData = data
        .map((trade) => `${trade.ticker} ${trade.shareCount}`)
        .join("\n");
      navigator.clipboard
        .writeText(formattedData)
        .then(() => {
          alert("Data copied to clipboard!");
        })
        .catch((error) => {
          alert("Failed to copy data: " + error);
        });
    } else if (action === "Paste") {
      navigator.clipboard
        .readText()
        .then((clipboardData) => {
          try {
            const parsedData = JSON.parse(clipboardData);
          } catch (error) {
            alert("Failed to parse pasted data: " + error);
          }
        })
        .catch((error) => {
          alert("Failed to read clipboard data: " + error);
        });
    }
    handleMenuClose();
  };

  return (
    <>
      <Box
        sx={{
          border: `1px solid ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "2px",
          width: "100%",
        }}
      >
        <Box sx={{ maxHeight: "350px", overflowY: "auto" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>Custodian: {custodian}</h3>
            <IconButton onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleMenuItemClick("Copy")}>
                Copy
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("Paste")}>
                Paste
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("Text")} disabled>
                Fetch via API
              </MenuItem>
            </Menu>
          </Box>

          <Table size="small" stickyHeader aria-label="a dense table">
            <VantageListHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHeader}
              noStatus={true}
              customizeFont={12}
              rowCount={data.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    overflow: "auto",
                    "&:hover": {
                      background: theme.palette.grey[200],
                      cursor: "pointer",
                    },
                    "& > .MuiTableCell-root": {
                      paddingTop: "0px",
                      paddingBottom: "0px !important",
                      lineHeight: "1.2",
                      "& .MuiInputBase-input": {
                        padding: "0px 0px !important",
                      },
                      "& .MuiIconButton-root": {
                        padding: "0px",
                      },
                    },
                  }}
                >
                  <Tablecell align="left">{row?.ticker}</Tablecell>
                  <Tablecell align="right">
                    {formatValue(row?.shareCount, true)}
                  </Tablecell>
                  <TableCell
                    align="right"
                    sx={{ borderLeft: "none", padding: "5px" }}
                  >
                    <TextField
                      value={row.available}
                      onChange={(e) =>
                        handleAvailableChange(e, index, row?.ticker)
                      }
                      size="small"
                      variant="standard"
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "12px",
                          marginRight: "0.5ch",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          textAlign: "right",
                        },
                      }}
                      inputProps={{
                        type: "number",
                        step: "any",
                        style: {
                          textAlign: "right",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          MozAppearance: "textfield",
                          WebkitAppearance: "none",
                          "&::-webkit-outer-spin-button": {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                          "&::-webkit-inner-spin-button": {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                        },
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </>
  );
};

export default Locates;
