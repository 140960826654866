import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Loader = ({
  noMin,
  mTrue,
  msg,
  marketLoader = false,
  crossingOrder = false,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight={noMin ? "" : "100vh"}
    >
      <CircularProgress
        color="primary"
        sx={{ marginTop: mTrue ? "100px" : "" }}
      />
      {marketLoader && (
        <>
          <Typography
            variant="h4"
            sx={{
              marginTop: "10px",
              fontSize: 30,
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Fetching Market Data
          </Typography>
          <Typography variant="h5" sx={{ maxWidth: 500, textAlign: "center" }}>
            Please be patient, as this process may take a few moments to
            retrieve the most accurate and up-to-date information.
          </Typography>
        </>
      )}
      {crossingOrder && (
        <>
          <Typography
            variant="h4"
            sx={{
              marginTop: "10px",
              fontSize: 30,
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Creating Crossing Orders
          </Typography>
          <Typography variant="h5" sx={{ maxWidth: 500, textAlign: "center" }}>
            Please be patient. Rebalancer orders are sent to crossing engine.
            This process may take a few moments to complete.
          </Typography>
        </>
      )}

      {msg && !marketLoader ? (
        <>
          <Typography
            variant="h4"
            sx={{
              marginTop: "10px",
              fontSize: 30,
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Please wait...
          </Typography>
          <Typography variant="h5" sx={{ maxWidth: 500, textAlign: "center" }}>
            Please be patient, as this process may take a few moments to
            retrieve the most accurate and up-to-date information.
          </Typography>
        </>
      ) : null}
    </Box>
  );
};

export default Loader;
