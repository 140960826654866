import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  useTheme,
  IconButton,
} from "@mui/material";
import RulesForm from "./RulesForm";
import CloseIcon from "@mui/icons-material/Close";

const Blacklist = (props) => {
  const theme = useTheme();
  const {
    title,
    openBlacklistPopup,
    setBlacklistOpenPopup,
    Strategies,
    recordForBlacklist,
    strategies,
    BlacklistData,
  } = props;

  return (
    <Dialog
      fullWidth
      open={openBlacklistPopup}
      sx={{
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "1000px",
          height: "100vh",
        },
      }}
    >
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          align="center"
          style={{ flexGrow: 1, fontSize: "20px" }}
        >
          {title}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => setBlacklistOpenPopup(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{ height: "50vh", overflow: "auto", borderBottom: "0px" }}
        dividers
      >
        <RulesForm
          Strategies={Strategies}
          BlacklistData={BlacklistData}
          recordForBlacklist={recordForBlacklist}
          setBlacklistOpenPopup={setBlacklistOpenPopup}
        />
      </DialogContent>
    </Dialog>
  );
};

export default Blacklist;
