import React, { useEffect, useState } from "react";
import { Container, Grid, Button, Box, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TABLE_CROSS_TRADE as tableHeader } from "../../../../../Methods";
import { useSelector } from "react-redux";
import CrossingTradesTable from "./components/CrossingTradesTable";
import Loader from "../../../../../Loader";

const FundRebalanceStep6 = ({ setCurrentStep, onPrevious }) => {
  const { crossingLoader, AllTrades } = useSelector((state) => state.todos);
  const [combinedOrders, setCombinedOrders] = useState([]);

  useEffect(() => {
    if (
      AllTrades &&
      Array.isArray(AllTrades?.realOrders) &&
      Array.isArray(AllTrades?.crossingOrders)
    ) {
      const combined = [...AllTrades.realOrders, ...AllTrades.crossingOrders];
      setCombinedOrders(combined);
    } else {
      setCombinedOrders([]);
    }
  }, [AllTrades]);
  const onNext = () => {
    setCurrentStep((prev) => prev + 1);
  };
  return (
    <Container disableGutters maxWidth="100%" sx={{ marginTop: 2 }}>
      {crossingLoader ? (
        <Loader mTrue={false} crossingOrder={true} />
      ) : (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "35%",
                }}
              >
                <Box
                  sx={{
                    marginBottom: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
                    Crossing Trades
                  </Typography>
                </Box>
              </div>
              {combinedOrders && combinedOrders.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    onClick={onNext}
                    sx={{ width: "150px", height: "40px" }}
                  >
                    Next
                  </Button>
                </Box>
              ) : null}
            </div>
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "45%",
                justifyContent: "space-between",
              }}
            >
              {combinedOrders ? (
                <CrossingTradesTable
                  crossingTrades={combinedOrders}
                  tableHeader={tableHeader}
                />
              ) : (
                <p>No data available</p>
              )}
            </div>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default FundRebalanceStep6;
