import React, { useEffect, useState } from "react";
import { Container, Grid, Button, Box } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  TABLE_HEAD_FUND_REBALANCE_EXISTING_DATA,
  TABLE_HEAD_FUND_REBALANCE_NEW_DATA,
} from "../../../../../Methods";
import { dummyData } from "../../../../../dummyData";
import RebalanceDetailedInfo from "../../../RebalanceDetailedInfo";
import ExistingFundRebalance from "./components/ExistingFundRebalance";
import NewFundRebalance from "./components/NewFundRebalance";
import { useDispatch, useSelector } from "react-redux";
import CrossingTradeRebalancer from "../../../../../Popup/CrossingTrades/CrossingTradeRebalancer";
import { updateLoading } from "../../../../../../Redux/Reducers/todoReducers";

const FundRebalanceStep1 = ({ setCurrentStep, onPrevious, sleeve }) => {
  const { sleeveDataStageOne, loading } = useSelector((state) => state.todos);
  const [existingArray, setExistingArray] = useState([]);
  const [newArray, setNewArray] = useState([]);
  const [openCrossPopup, seOpenCrossPopup] = useState(false);
  const [onceClicked, setOnceClicked] = useState(false);
  const dispatch = useDispatch();

  const onNext = () => {
    if (onceClicked) {
      seOpenCrossPopup(true);
      return;
    }

    setOnceClicked(true);

    const rebalancerIds = Object.values(sleeveDataStageOne)?.map(
      (strategy) => strategy?.rebalancer?.rebalancerId
    );

    const socketRequest = {
      action: "create_rebalancer_orders",
      data: {
        rebalancerIds: rebalancerIds,
      },
    };

    dispatch(updateLoading(true));
    window.clientSocks.send(JSON.stringify(socketRequest));
    seOpenCrossPopup(true);
  };

  return (
    <Container disableGutters maxWidth="100%" sx={{ marginTop: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "50%",
              }}
            >
              <RebalanceDetailedInfo
                sleeve={sleeve}
                setExistingArray={setExistingArray}
                setNewArray={setNewArray}
                sleeveDataStageOne={sleeveDataStageOne}
              />
            </div>
            {Object.keys(sleeveDataStageOne)?.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  justifyContent: "flex-end",
                }}
              >
                {/* <Button
                  variant="contained"
                  color="grey"
                  startIcon={<ArrowBackIcon />}
                  onClick={onPrevious}
                  sx={{ width: "150px", height: "40px" }}
                >
                  BACK
                </Button> */}

                <Button
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  onClick={onNext}
                  sx={{ width: "150px", height: "40px" }}
                >
                  Next
                </Button>
              </Box>
            ) : null}
          </div>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "40%",
              justifyContent: "space-between",
            }}
          >
            {dummyData && TABLE_HEAD_FUND_REBALANCE_EXISTING_DATA ? (
              <ExistingFundRebalance
                existingArray={existingArray}
                tableHeader={TABLE_HEAD_FUND_REBALANCE_EXISTING_DATA}
              />
            ) : (
              <p>No data available</p>
            )}
            {dummyData && TABLE_HEAD_FUND_REBALANCE_NEW_DATA ? (
              <NewFundRebalance
                newArray={newArray}
                tableHeader={TABLE_HEAD_FUND_REBALANCE_NEW_DATA}
              />
            ) : (
              <p>No data available</p>
            )}
          </div>
        </Grid>
      </Grid>
      <CrossingTradeRebalancer
        title="Orders"
        openCrossPopup={openCrossPopup}
        seOpenCrossPopup={seOpenCrossPopup}
        setCurrentStep={setCurrentStep}
      />
    </Container>
  );
};

export default FundRebalanceStep1;
