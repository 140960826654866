import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  Divider,
  Table,
  TableCell,
  TableRow,
  TableBody,
  useTheme,
  IconButton,
  Box,
  TextField,
} from "@mui/material";
import {
  Tablecell,
  formatValue,
  TABLE_CROSS_TRADE_ACTIVITY as tableHeader1,
  TABLE_CROSS_TRADE_RESULTING as tableHeader2,
} from "../../../component/Methods";
import VantageListHead from "../../../component/ListHead/SignalList";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Popup from "../../../component/Popup";
import AddTradeDetails from "../../../Pages/AddTradeDetails";
import EditTradeDetails from "../../../Pages/EditTradeDetails";
import PopUp from "../PopUp";

const CrossingTrade = (props) => {
  const theme = useTheme();

  const {
    title,
    openCrossPopup,
    seOpenCrossPopup,
    TradeForEdit,
    setTradeForEdit,
  } = props;
  const [openPopup, setOpenPopup] = useState(false);
  const [openAddPopup, setOpenAddPopup] = useState(false);

  const [activityData, setActivityData] = useState([
    {
      activity: "Buy Long",
      shares: 170,
      strategy: "Strategy 3",
      action:"BuyShort",
      
      custodian: "cant",
      reason: "Rebalance",
    },
    {
      activity: "Buy Long",
      shares: 100,
      strategy: "Strategy 5",
      custodian: "",
      reason: "New Trade",
    },
    {
      activity: "Sell Short",
      shares: 10,
      strategy: "Strategy 2",
      custodian: "",
      reason: "",
    },
  
  ]);

  const [resultingData] = useState([
    {
      activity: "Buy Long",
      shares: 170,
      strategy: "Strategy 3",
      custodian: "cant",
    },
    {
      activity: "Buy Long",
      shares: 100,
      strategy: "Strategy 5",
      custodian: "",
    },
  ]);

  const deleteActivity = (index) => {
    const newData = [...activityData];
    newData.splice(index, 1);
    setActivityData(newData);
  };

  const handleActivityChange = (e, index, field) => {
    const newData = [...activityData];
    newData[index][field] = e.target.value;
    setActivityData(newData);
  };

  const addTrade = () => {
    // const newTrade = {
    //   activity: "",
    //   shares: 0,
    //   strategy: "",
    //   custodian: "",
    // };
    // setActivityData([...activityData, newTrade]);
    setOpenAddPopup(true);
  };
  const handleClose = () => {
    setOpenAddPopup(false);
  };

  const openTradeInPopup = (item) => {
    // setTradeForEdit(item);
    setOpenPopup(true);
  };

  return (
    <Dialog
      open={openCrossPopup}
      fullWidth
      sx={{
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "1000px",
          height: "100vh",
        },
      }}
    >
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          align="center"
          style={{ flexGrow: 1, fontSize: "20px" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h5" component="div" gutterBottom>
          Activity
        </Typography>
        <Box
          sx={{
            maxHeight: "310px",
            overflowY: "auto",
          }}
        >
          {/* <Table
            size="small"
            stickyHeader
            aria-label="a dense table"
            sx={{
              borderCollapse: "collapse",
              border: "solid 1px #eee",
              marginTop: "-1px",
            }}
          >
            <VantageListHead
              headLabel={tableHeader1}
              noStatus={true}
              rowCount={activityData.length}
            />
            <TableBody>
              {activityData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "& > .MuiTableCell-root": {
                      paddingTop: "1px",
                      paddingBottom: "0px !important",
                      lineHeight: "1.2",
                      "& .MuiInputBase-input": {
                        padding: "0px 0px !important",
                      },
                      "& .MuiIconButton-root": {
                        padding: "0px",
                      },
                    },
                    "&:hover": {
                      background: theme.palette.grey[200],
                      cursor: "pointer",
                    },
                  }}
                >
                  <TableCell align="right" sx={{ borderRight: "none" }}>
                    <TextField
                      value={row.strategy}
                      // onChange={(e) => {
                      //   handleActivityChange(e, index, "activity");
                      // }}
                      size="small"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "12px",
                          fontWeight: "500",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell align="right" sx={{ borderRight: "none" }}>
                    <TextField
                      value={row.shares}
                      onChange={(e) => handleActivityChange(e, index, "shares")}
                      size="small"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "12px",
                          fontWeight: "500",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell align="right" sx={{ borderRight: "none" }}>
                    <TextField
                      value={row.strategy}
                      // onChange={(e) =>
                      //   handleActivityChange(e, index, "strategy")
                      // }
                      size="small"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "12px",
                          fontWeight: "500",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell align="right" sx={{ borderRight: "none" }}>
                    <TextField
                      value={row.reason}
                      // onChange={(e) =>
                      //   handleActivityChange(e, index, "reason")
                      // }
                      size="small"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "12px",
                          fontWeight: "500",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        },
                      }}
                    />
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      borderRight: "none",
                    }}
                  >
                    <IconButton
                      sx={{ height: "20px", Minwidth: "10px" }}
                      onClick={() => {
                        openTradeInPopup(row);
                      }}
                    >
                      <EditIcon
                        sx={{
                          color: "#808080",
                          width: "20px",
                          height: "15px",
                        }}
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => deleteActivity(index)}
                      aria-label="delete"
                      sx={{
                        height: "15px",
                        width: "15px",
                      }}
                    >
                      <DeleteIcon sx={{ height: "15px", width: "15px" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table> */}
          <Table
            size="small"
            stickyHeader
            aria-label="a dense table"
            sx={{
              borderCollapse: "collapse",
              border: "solid 1px #eee",
              marginTop: "-1px",
            }}
          >
            <VantageListHead
              headLabel={tableHeader1}
              noStatus={true}
              customizeFont={12}
              rowCount={activityData.length}
            />

            <TableBody>
              {activityData?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "& > .MuiTableCell-root": {
                      paddingTop: "1px",
                      paddingBottom: "0px !important",
                      lineHeight: "1.2",
                      "& .MuiInputBase-input": {
                        padding: "0px 0px !important",
                      },
                      "& .MuiIconButton-root": {
                        padding: "0px",
                      },
                    },
                    "&:hover": {
                      background: theme.palette.grey[200],
                      cursor: "pointer",
                    },
                  }}
                >
                  <Tablecell
                    align="left"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row.strategy}
                  </Tablecell>
                  <Tablecell
                    align="left"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row?.action}
                  </Tablecell>
                  <Tablecell
                    align="left"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row?.ticker}
                  </Tablecell>
                  <Tablecell
                    align="right"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row?.shareCount !== undefined && row?.shareCount != null
                      ? row?.shareCount < 0
                        ? `(${Math.abs(row?.shareCount)})`
                        : `${formatValue(row?.shareCount)}`
                      : ""}
                  </Tablecell>

                  <Tablecell
                    align="center"
                    sx={{
                      borderRight: "none",
                    }}
                  >
                    <IconButton
                      sx={{ height: "20px", Minwidth: "10px" }}
                      onClick={() => {
                        openTradeInPopup(row);
                      }}
                    >
                      <EditIcon
                        sx={{
                          color: "#808080",
                          width: "20px",
                          height: "15px",
                        }}
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => deleteActivity(index)}
                      aria-label="delete"
                      sx={{
                        height: "15px",
                        width: "15px",
                      }}
                    >
                      <DeleteIcon sx={{ height: "15px", width: "15px" }} />
                    </IconButton>
                  </Tablecell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            variant="outlined"
            onClick={addTrade}
            sx={{ marginTop: "10px", width: "100%" }}
          >
            Add a Trade
          </Button>
        </Box>

        <Divider
          sx={{ margin: "20px 0", height: "4px", backgroundColor: "grey" }}
        />

        <Typography variant="h5" component="div" gutterBottom>
          Resulting Trade Entries
        </Typography>
        <Table
          size="small"
          stickyHeader
          aria-label="a dense table"
          sx={{
            borderCollapse: "collapse",
            border: "solid 1px #eee",
            marginTop: "-1px",
          }}
        >
          <VantageListHead
            headLabel={tableHeader2}
            noStatus={true}
            rowCount={resultingData.length}
          />
          <TableBody>
            {resultingData.map((row, index) => (
              <TableRow key={index}>
                <Tablecell>{row.activity}</Tablecell>
                <Tablecell>{row.shares}</Tablecell>
                <Tablecell>{row.strategy}</Tablecell>
                <Tablecell>{row.custodian}</Tablecell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Grid
          item
          xs={12}
          margin="20px 1px 20px 1px"
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            sx={{
              width: "40%",
            }}
          >
            SAVE
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "40%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              seOpenCrossPopup(false);
            }}
          >
            CANCEL
          </Button>
        </Grid>
      </DialogContent>
      <PopUp
        title="Trade Entry Form"
        openPopup={openAddPopup}
        onClose={handleClose}
      >
        <AddTradeDetails setOpenAddPopup={setOpenAddPopup} />
      </PopUp>
      <Popup
        title="Edit Trade Entry"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <EditTradeDetails
          setOpenPopup={setOpenPopup}
          TradeForEdit={TradeForEdit}
          setTradeForEdit={setTradeForEdit}
        />
      </Popup>
    </Dialog>
  );
};

export default CrossingTrade;
