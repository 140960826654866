export const colorPalette = [
  "#09122C", // Bright Yellow
  "#432E54", // Bright Orange
  "#006A67", // Vivid Purple
  "#8EB486", // Vivid Red
  "#803D3B", // Bright Green
  "#C98474", // Vivid Parrot Green
  "#363062", // Bright Sky Blue
  "#6666FF", // Medium Blue
  "#FF3333", // Bright Red
  "#81689D", // Light Grey
  "#FF66FF", // Bright Lavender
  "#FF9933", // Bright Peach
  "#3399FF", // Light Blue
  "#3399FF", // Light Cyan
  "#FF1A66", // Salmon Pink
  "#33FF99", // Turquoise Green
  "#99FF33", // Mint Green
  "#FF3399", // Bright Rose
  "#3333FF", // Bright Periwinkle
  "#FF66CC", // Blush Pink
  "#FF9933", // Burnt Orange
  "#99CC66", // Sage Green
  "#99CCFF", // Ice Blue
  "#FFCC66", // Coral
  "#66FF33", // Spring Green
  "#550A46", // Hot Pink
  "#99FF80", // Mint Green
  "#FF3399", // Fuchsia
  "#FF0066", // Scarlet
  "#6699FF", // Cornflower Blue
  "#CC99FF", // Lilac
];
