import React, { useState } from "react";
import { useEffect } from "react";
import Topbar from "../component/NavComponents/Topbar";
import RebalancerLayout from "../component/Layouts/RebalancerLayout";
import { Helmet } from "react-helmet";
import { clearRebalancer } from "../Redux/Actions/todoActions";
import { useDispatch } from "react-redux";

const Rebalancer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearRebalancer())
  }, []);
  return (
    <>
      <Helmet>
        <title>Rebalancer</title>
      </Helmet>
      <Topbar title="Rebalancer" />
      <RebalancerLayout />
    </>
  );
};
export default Rebalancer;
