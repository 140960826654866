import Footer from "../Footer";
import Header from "../Header";
import RebalancerTradeTable from "../RebalancerTradesDetails/RebalancerTradeTable";

const RebalancedTrades = () => {
  return (
    <>
      <Header>
        <RebalancerTradeTable />
      </Header>
    </>
  );
};
export default RebalancedTrades;
