import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  Table,
  TableRow,
  TableBody,
  useTheme,
  IconButton,
  Box,
} from "@mui/material";
import {
  Tablecell,
  applySortFilter,
  calculateAndFormatNoAbsInt,
  findStrategy,
  formatValue,
  getComparator,
  hashAction,
  hashPosition,
  TABLE_SINGLE_LINE_TRADE as tableHeader,
} from "../../../component/Methods";
import VantageListHead from "../../../component/ListHead/SignalList";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { updateLoading } from "../../../Redux/Reducers/todoReducers";
import Loader from "../../Loader";

const CrossingTradeRebalancer = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    title,
    openCrossPopup,
    seOpenCrossPopup,
    setCurrentStep,
    onceClicked,
  } = props;
  const { orders, sleeveDataStageOne, strategies, load } = useSelector(
    (state) => state.todos
  );
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ticker");

  useEffect(() => {
    const filteredOrders = orders?.map((order) => {
      const strategy = findStrategy(order?.strategy, strategies);
      const ticker = order?.ticker?.symbol;
      const action = hashAction(order?.action) + hashPosition(order?.position);
      const distribute = Object.entries(order?.distribution || {})?.reduce(
        (acc, [key, value]) => {
          const [fund, _] = key.split("#");

          if (!acc[fund]) {
            acc[fund] = [];
          }

          acc[fund].push({
            shareCount: Number(value),
          });

          return acc;
        },
        {}
      );
      let totalShareCount = 0;
      if (distribute) {
        Object.keys(distribute)?.forEach((key) => {
          const entries = distribute[key];
          totalShareCount += entries.reduce(
            (sum, entry) => sum + entry?.shareCount,
            0
          );
        });
      }

      return {
        strategy: strategy,
        ticker: ticker,
        action: action,
        shareCount: totalShareCount,
      };
    });

    const filteredUsers = applySortFilter(
      filteredOrders,
      getComparator(order, orderBy)
    );
    setData(filteredUsers);
  }, [order, orderBy, orders, sleeveDataStageOne]);

  const onNext = () => {
    const rebalancerIds = Object.values(sleeveDataStageOne)?.map(
      (strategy) => strategy?.rebalancer?.rebalancerId
    );

    const socketRequest = {
      action: "create_rebalancer_crossing_orders",
      data: {
        rebalancerIds: rebalancerIds,
      },
    };

    window.clientSocks.send(JSON.stringify(socketRequest));
    seOpenCrossPopup(false);
    setCurrentStep((prev) => prev + 1);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const deleteActivity = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };

  return (
    <Dialog
      open={openCrossPopup}
      fullWidth
      sx={{
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "1000px",
          height: "100vh",
        },
      }}
    >
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          align="center"
          style={{ flexGrow: 1, fontSize: "20px" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        {load ? (
          <Loader noMin={40} />
        ) : (
          <Box
            sx={{
              maxHeight: "310px",
              overflowY: "auto",
            }}
          >
            <Table
              size="small"
              stickyHeader
              aria-label="a dense table"
              sx={{
                borderCollapse: "collapse",
                border: "solid 1px #eee",
                marginTop: "-1px",
              }}
            >
              <VantageListHead
                order={order}
                orderBy={orderBy}
                headLabel={tableHeader}
                noStatus={true}
                customizeFont={12}
                rowCount={data.length}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {data?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "& > .MuiTableCell-root": {
                        paddingTop: "1px",
                        paddingBottom: "0px !important",
                        lineHeight: "1.2",
                        "& .MuiInputBase-input": {
                          padding: "0px 0px !important",
                        },
                        "& .MuiIconButton-root": {
                          padding: "0px",
                        },
                      },
                      "&:hover": {
                        background: theme.palette.grey[200],
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Tablecell
                      align="left"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row.strategy}
                    </Tablecell>
                    <Tablecell
                      align="left"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row?.action}
                    </Tablecell>
                    <Tablecell
                      align="left"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row?.ticker}
                    </Tablecell>
                    <Tablecell
                      align="right"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row?.shareCount !== undefined && row?.shareCount != null
                        ? row?.shareCount < 0
                          ? `(${Math.abs(row?.shareCount)})`
                          : `${calculateAndFormatNoAbsInt(row?.shareCount)}`
                        : ""}
                    </Tablecell>

                    {/* <Tablecell
                      align="center"
                      sx={{
                        borderRight: "none",
                      }}
                    >
                      <IconButton
                        onClick={() => deleteActivity(index)}
                        aria-label="delete"
                        sx={{
                          height: "15px",
                          width: "15px",
                        }}
                      >
                        <DeleteIcon sx={{ height: "15px", width: "15px" }} />
                      </IconButton>
                    </Tablecell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}
        <Grid
          item
          xs={12}
          margin="20px 1px 20px 1px"
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            sx={{
              width: "40%",
            }}
            onClick={onNext}
          >
            Create Crossing Orders
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "40%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              seOpenCrossPopup(false);
            }}
          >
            CANCEL
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CrossingTradeRebalancer;
