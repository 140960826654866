import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  Divider,
  ListItemIcon,
  IconButton,
  Badge,
  Button,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeUser } from "../../../Redux/Actions/UserActions";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Popup from "../../Popup";
import Sync from "../Topbar/Sync";
const Topbar = ({ title }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSync, setIsSync] = useState(false);
  const [count, setCount] = useState(0);
  const [managerName, setmanagerName] = useState("");
  const { user, userInfo } = useSelector((state) => state?.users);
  const { managers, isStrategist, notifications, isInvestor } = useSelector(
    (state) => state.todos
  );

  useEffect(() => {
    if(isStrategist){
      setmanagerName("Strategist");
    }else{
      setmanagerName(managers[0]?.name)
    }
  }, [isStrategist, managers,isInvestor]);
  useEffect(() => {
    if (notifications && Array.isArray(notifications)) {
      const unreadCount = notifications.filter(
        (notification) => !notification?.isRead
      ).length;
      setCount(unreadCount);
    } else {
      setCount(0);
    }
  }, [notifications]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleChangePass = () => {
    handleMenuClose();
    navigate("/userProfile");
  };
  const handleChangeLogout = () => {
    handleMenuClose();
    dispatch(removeUser());
    window.clientSocks = null;
    navigate("/login");
  };
  const handleChangeSetting = () => {
    handleMenuClose();
    navigate("/settings");
  };
  const handleNotification = () => {
    window.clientSocks.send(`{"action":"modify_notifications"}`);
    navigate("/notifications");
  };
  const doesProfilePicExist = () => {
    const img = new Image();
    img.src = `https://users.precept.cloud/${user?.username?.toLowerCase()}.jpg`;
    img.onerror = () => false; // Handle error silently
    return img.complete || img.height > 0;
  };
  const getUserAvatar = () => {
    const profilePicExists = doesProfilePicExist();

    if (profilePicExists) {
      return (
        <Avatar
          src={`https://users.precept.cloud/${user?.username?.toLowerCase()}.jpg`}
          alt="user-picture"
          sx={{
            border: `1px solid ${theme.palette.grey[900]}`,
            cursor: "pointer",
            width: "50px",
            height: "50px",
          }}
          onClick={handleMenuClick}
        />
      );
    } else {
      const initials =
        (userInfo?.firstName?.charAt(0) || "") +
        (userInfo?.lastName?.charAt(0) || "");

      return (
        <Avatar
          alt="user-initials"
          sx={{
            border: `1px solid ${theme.palette.grey[900]}`,
            cursor: "pointer",
            width: "50px",
            height: "50px",
          }}
          onClick={handleMenuClick}
        >
          {initials}
        </Avatar>
      );
    }
  };
  const handleSync = () => {
    setIsSync(true);
  };
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={1}
      sx={{ backgroundColor: theme.palette.grey[200] }}
    >
      <Box display="flex">
        <Typography
          variant="h6"
          component="div"
          fontSize="25px"
          marginLeft="10px"
        >
          {title}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="flex-end" px={2}>
        {!isStrategist && !isInvestor ? (
          <IconButton
            size="large"
            aria-label="show new notifications"
            color="#424e5f"
            onClick={handleNotification}
            style={{ marginTop: "-7", marginRight: 10 }}
          >
            <Badge badgeContent={count} color="error">
              <NotificationsIcon color="#000" fontSize="inherit" />
            </Badge>
          </IconButton>
        ) : (
          <></>
        )}
        {getUserAvatar()}
        <Box sx={{ marginTop: 1, marginLeft: 1 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "13px",
            }}
          >
            {`${userInfo?.firstName} ${userInfo?.lastName}`}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "13px",
              color: "grey",
            }}
          >
            {managerName}
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {/* <MenuItem onClick={handleChangePass}>
    <ListItemIcon>
      <AccountCircleIcon />
    </ListItemIcon>
    User Profile
  </MenuItem> */}
          {managers && managers.length > 0 && !isInvestor ? (
            <MenuItem onClick={handleChangeSetting}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              Settings
            </MenuItem>
          ) : null}
          <Divider />
          <MenuItem onClick={handleChangeLogout} style={{ color: "red" }}>
            <ListItemIcon>
              <ExitToAppIcon style={{ color: "red" }} />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Box>
      <Popup
        title="SYNC DATA ITEMS"
        openPopup={isSync}
        setOpenPopup={setIsSync}
      >
        <Sync setIsSync={setIsSync} />
      </Popup>
    </Box>
  );
};

export default Topbar;
