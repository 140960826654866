import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  Box,
  useTheme,
} from "@mui/material";
import { hashGetBlacklistCritera, hashGetBlacklistType } from "../../Methods";
import { useDispatch } from "react-redux";
import { updateLoading } from "../../../Redux/Reducers/todoReducers";
import SecurityForm from "./SecurityForm";

const Blacklist = (props) => {
  const theme = useTheme();
  const {
    title,
    openBlacklistPopup,
    setBlacklistOpenPopup,
    BlacklistData,
    managerId,
  } = props;

  const [securities, setSecurities] = useState([]);
  const [actualSecurities, setActualSecurities] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (openBlacklistPopup) {
      if (BlacklistData && BlacklistData?.rules?.length > 0) {
        const newRules = [];
        const filteredRules = BlacklistData?.rules.filter(
          (rule) => rule.ticker
        );
        setActualSecurities(filteredRules);

        filteredRules?.forEach((rule) => {
          const { type, maximum, minimum, ticker } = rule;
          let operation = "NotAllowed";
          let value = null;
          if (minimum !== undefined && maximum !== undefined) {
            operation = "=";
            value = minimum;
          } else if (minimum !== undefined) {
            operation = ">";
            value = minimum;
          } else if (maximum !== undefined) {
            operation = "<";
            value = maximum;
          }
          newRules.push({
            selectedTickers: ticker?.symbol,
            rules: [
              {
                criteria: hashGetBlacklistCritera(type),
                operation,
                value,
              },
            ],
          });
        });
        setSecurities(newRules);
      }
    }
  }, [openBlacklistPopup, BlacklistData]);

  const handleSave = () => {
    const isModification =
      BlacklistData && BlacklistData.rules && BlacklistData.rules.length > 0;

    const data = {
      action: isModification ? "modify_blacklist" : "create_blacklist_rule",
      data: {
        fundBlacklist: false,
        rules: isModification ? { add: [], modify: [], remove: [] } : [],
      },
    };
    if (isModification) {
      data.data.action = "add";
      data.data.managerId = managerId;
    } else {
      data.data.manager = managerId;
    }
    const processRule = (ruleSet, isModification) => {
      const { selectedTickers, rules } = ruleSet;
      const ticker = selectedTickers;

      rules.forEach((rule) => {
        const { criteria, operation, value } = rule;
        const ruleObj = {
          type: hashGetBlacklistType(criteria),
          ticker: ticker,
          [operation === "="
            ? "minimum"
            : operation === ">"
            ? "minimum"
            : "maximum"]: Number(value),
        };
        if (isModification) {
          const existingRule = actualSecurities?.find(
            (r) => r.ticker.symbol === ticker && r.type === ruleObj.type
          );

          if (existingRule) {
            const hasChange =
              existingRule.maximum !== ruleObj.maximum ||
              existingRule.minimum !== ruleObj.minimum;
            if (
              hasChange &&
              !data.data.rules.modify.some(
                (r) => r.ticker === ruleObj.ticker && r.type === ruleObj.type
              )
            ) {
              data.data.rules.modify.push(ruleObj);
            }
          } else {
            data.data.rules.add.push(ruleObj);
          }
        } else {
          data.data.rules.push(ruleObj);
        }
      });
    };

    if (isModification) {
      securities?.forEach((ruleSet) => processRule(ruleSet, true));
      actualSecurities?.forEach((existingRule) => {
        const { ticker, type, minimum, maximum } = existingRule;
        const ruleExistsInCurrent = securities.some((ruleSet) =>
          ruleSet.rules.some(
            (rule) =>
              hashGetBlacklistType(rule.criteria) === type &&
              ruleSet.selectedTickers === ticker.symbol
          )
        );

        if (!ruleExistsInCurrent) {
          const ruleObj = { ticker, type, minimum, maximum };
          data.data.rules.remove.push(ruleObj);
        }
      });
    } else {
      securities.forEach((ruleSet) => processRule(ruleSet, false));
    }

    window.clientSocks.send(JSON.stringify(data));
    setBlacklistOpenPopup(false);
    dispatch(updateLoading(true));
  };
  const handleDelete = () => {
    const data = {
      action: "delete_blacklist",
      data: {
        fundBlacklist: false,
        blacklist: managerId,
      },
    };

    window.clientSocks.send(JSON.stringify(data));
    setBlacklistOpenPopup(false);
    dispatch(updateLoading(true));
  };
  return (
    <Dialog
      fullWidth
      open={openBlacklistPopup}
      sx={{
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "1000px",
          height: "100vh",
        },
      }}
    >
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          align="center"
          style={{ flexGrow: 1, fontSize: "20px" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ height: "50vh", overflow: "auto" }} dividers>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ marginTop: "20px" }}>
            <SecurityForm
              securities={securities}
              setSecurities={setSecurities}
            />
          </Box>
        </Box>
      </DialogContent>
      <Grid
        item
        xs={12}
        margin="20px 10px 20px 10px"
        display="flex"
        justifyContent="space-between"
        columnGap={1}
      >
        <Button variant="contained" sx={{ width: "40%" }} onClick={handleSave}>
          SAVE
        </Button>
        <Button
          variant="contained"
          sx={{
            width: "40%",
            background: theme.palette.red[200],
            "&:hover": { background: theme.palette.red[300] },
          }}
          onClick={handleDelete}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          sx={{
            width: "40%",
            background: "#B2BEB5",
            color: "#000",
            "&:hover": { background: "#808080" },
          }}
          onClick={() => {
            setBlacklistOpenPopup(false);
            setSecurities([]);
            setActualSecurities([]);
          }}
        >
          CANCEL
        </Button>
      </Grid>
    </Dialog>
  );
};

export default Blacklist;
