import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
} from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import SendTrades from "../../Popup/SendTrades";
import AddTradeDetails from "../../../Pages/AddTradeDetails";
import Popup from "../../Popup";
import { useSelector } from "react-redux";
import SendTradesForAccounting from "../../Popup/SendTradesForAccounting";
import { StyledRoot, StyledTab } from "../../Methods";
import PopUp from "../../Popup/PopUp";

const ListToolbar = ({ headLabel, setTableHead, data }) => {
  const now = new Date(new Date().setDate(new Date().getDate() - 1));
  let startOfDay = new Date(now - (now % 86400000)).getTime();
  let endOfDay = new Date(now - (now % 86400000) + 86400000).getTime();
  let shareCount = 0;

  const [anchorEl, setAnchorEl] = useState("");
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [tradesPopup, setTradesOpenPopup] = useState(false);
  const [confirmation, setconfirmation] = useState(true);
  const [opensave, setopensave] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);
  const [value, setValue] = React.useState(0);
  const user = useSelector((state) => state?.users?.user);
  useEffect(() => {}, [user?.username]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleGenerateFile = () => {
    window.clientSocks.send('{"action": "get_eod"}');

    setTradesOpenPopup(true);
  };
  const handleGenerateAccounting = () => {
    window.clientSocks.send(
      '{"action": "create_eod","data":{"eod_type":"accounting"}}'
    );

    setOpenAccount(true);
  };

  const updateNewTrades = () => {
    if (confirmation) {
      data?.map((val, key) => {
        if (
          data[key]?.manuals?.length > 0 &&
          data[key].orders.slice(-1)[0].date >= startOfDay &&
          data[key].orders.slice(-1)[0].date <= endOfDay &&
          data[key]?.manuals.slice(-1)[0]?.shareCount <
            data[key].orders.slice(-1)[0].amount
        ) {
          shareCount =
            data[key].orders.slice(-1)[0].amount -
            data[key]?.manuals.slice(-1)[0]?.shareCount;
        } else if (
          data[key]?.fill != undefined &&
          data[key].orders.slice(-1)[0].date >= startOfDay &&
          data[key].orders.slice(-1)[0].date <= endOfDay &&
          data[key].fill?.shareCount < data[key].orders.slice(-1)[0].amount
        ) {
          shareCount =
            data[key].orders.slice(-1)[0].amount - data[key]?.fill?.shareCount;
        } else if (
          data[key]?.manuals?.length === 0 ||
          (data[key]?.fill == undefined &&
            data[key].orders.slice(-1)[0].date >= startOfDay &&
            data[key].orders.slice(-1)[0].date <= endOfDay)
        )
          shareCount = data[key].orders.slice(-1)[0].amount;
        window.clientSocks.send(
          `{"action":"new_order", "data":{"orders": [${JSON.stringify({
            distribution: {},
            action: Number(data[key]?.orders.slice(-1)[0].action),
            position: Number(data[key]?.orders.slice(-1)[0].position),
            broker: data[key]?.orders.slice(-1)[0].broker,
            investment: data[key].orders.slice(-1)[0].investment,
            type: Number(data[key].orders.slice(-1)[0].type),
            amount: Number(shareCount),
            typePrice: Number(data[key].orders.slice(-1)[0].typePrice),
            instructions: data[key].orders.slice(-1)[0].instructions,
            routing: data[key].orders.slice(-1)[0].routing,
            ticker: data[key].orders.slice(-1)[0].ticker,
            currency: "USD",
            user: user?.username,
          })}]}}`
        );
      });
      setopensave(false);
      setconfirmation(false);
    }
  };
  const handleClose = () => {
    setOpenAddPopup(false);
  };

  return (
    <>
      <StyledRoot>
        <Box
          sx={{
            maxWidth: "100%",
            bgcolor: "background.paper",
            // marginLeft: "-25px",
            //marginRight: "10px",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            // variant="scrollable"
            // scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
                borderRadius: "50%",
                background: "#808080",
                height: "20px",
                width: "20px",
                mt: 2,
              },
            }}
            indicatorColor="white"
            textColor="primary"
          >
            <StyledTab
              onClick={() => {
                handleGenerateFile();
              }}
              label="Send trades to custodian"
            />
            <StyledTab
              onClick={() => {
                handleGenerateAccounting();
              }}
              label="Submit Trades to Accounting System"
            />
            <StyledTab
              onClick={() => {
                setopensave(true);
              }}
              label="Start New Day"
            />
          </Tabs>
        </Box>
        <Box>
          <Button
            text="Add New"
            variant="contained"
            sx={{ height: 40, width: 240, fontSize: "12px" }}
            onClick={() => {
              setOpenAddPopup(true);
            }}
          >
            ADD NEW TRADE
          </Button>
        </Box>
      </StyledRoot>
      <PopUp
        title="Trade Entry Form"
        openPopup={openAddPopup}
        onClose={handleClose}
      >
        <AddTradeDetails setOpenAddPopup={setOpenAddPopup} />
      </PopUp>

      <SendTrades
        title="Submit trades to Custodians"
        tradesPopup={tradesPopup}
        setTradesOpenPopup={setTradesOpenPopup}
      />

      <SendTradesForAccounting
        title="Submit trades to Accounting System"
        openAccount={openAccount}
        setOpenAccount={setOpenAccount}
      />

      <Dialog
        open={opensave}
        sx={{ width: "100%", height: "100%" }}
        onClick={() => {
          setopensave(false);
          setTradesOpenPopup(false);
        }}
      >
        <DialogTitle>
          <Typography
            variant="h6"
            component="div"
            align="center"
            style={{ flexGrow: 1, fontSize: "20px" }}
          >
            NEW DAY TRADE
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body" component="div" style={{ flexGrow: 1 }}>
            Are you sure you want to start new day of trading?
          </Typography>
          <Grid
            item
            xs={12}
            margin="20px 1px 20px 1px "
            display="flex"
            justifyContent="space-between"
          >
            <Button
              variant="contained"
              color="success"
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                height: "40px",
                width: "150px",
              }}
              onClick={() => {
                setconfirmation(true);
                updateNewTrades();
              }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                height: "40px",
                width: "150px",
                background: "#B2BEB5",
                color: "#000",
                "&:hover": { background: "#808080" },
              }}
              onClick={() => {
                setopensave(false);
              }}
            >
              CANCEL
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ListToolbar;
